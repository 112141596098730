import { useCallback } from 'react';
import { useWebSocket } from './useWebSocket';
import { useDispatch } from 'react-redux';
import { useUserData } from './useProfile';
import { CALL_TYPE } from 'utils/constants';
import { setCallRingingTo, setCurrentlyOnCall } from 'store/dialingReducer';
import { JitsiPayload } from 'models/DialingStateModel.type';

export function useCallSocketEmitter() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const callAccept = ({
    callFromUserId,
    callType,
    jitsi,
  }: {
    callFromUserId: string;
    callType: CALL_TYPE;
    jitsi: JitsiPayload;
  }) => {
    console.log('call accepting');
    socket.emit('callAccept', {
      callFromUserId,
      callAcceptedBy: userId,
      callType,
      jitsi,
    });
  };

  const callReject = ({
    callFromUserId,
    callType,
  }: {
    callFromUserId: string;
    callType: CALL_TYPE;
  }) => {
    console.log('Call reject');
    socket.emit('callReject', {
      callFromUserId,
      callRejectedBy: userId,
      callType,
    });
  };

  const callCancel = ({
    callCanceledTo,
    callType,
  }: {
    callCanceledTo: string;
    callType: CALL_TYPE;
  }) => {
    socket.emit('callCancel', {
      callCanceledTo,
      callCanceledBy: userId,
      callType,
    });
  };

  return { callAccept, callReject, callCancel };
}

export function useCallRing() {
  const socket = useWebSocket();
  const dispatch = useDispatch();
  const { userId, username, profileImageUrl } = useUserData();

  const callRing = ({
    callToUserId,
    callToContactId,
    callToUsername,
    callToUserImage,
    callType,
  }: {
    callToUserId: string;
    callToContactId: string;
    callType: CALL_TYPE;
    callToUsername: string;
    callToUserImage: string;
  }) => {
    const callToParam = {
      callToUserId,
      callFromUserId: userId,
      callFromUsername: username,
      callFromUserImage: profileImageUrl,
      callType,
    };
    // console.log('callRing:: ', callToParam);
    socket.emit('callRing', callToParam);
    dispatch(
      setCallRingingTo({
        callToUserId,
        callToContactId,
        callToUsername,
        callToUserImage,
        callType,
      })
    );
    dispatch(setCurrentlyOnCall(true));
  };

  return callRing;
}

export function useCallBusy() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const callBusy = ({ callFromUserId }: { callFromUserId: string }) => {
    socket.emit('isCallBusy', {
      callFromUserId,
      callBusyUserId: userId,
    });
  };

  return callBusy;
}

export function useCallEnd() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const callEnd = useCallback(
    ({
      callEndedTo,
      callEndedBy,
      callType,
    }: {
      callEndedTo: string;
      callEndedBy: string;
      callType: CALL_TYPE;
    }) => {
      socket.emit('callEnd', {
        callEndedTo,
        callEndedBy,
        callType,
      });
    },
    [userId, socket]
  );

  return callEnd;
}

// export default useCallSocketEmitter;
