import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { logout, storeCredentials } from 'utils/auth';
import { STORAGE } from 'utils/constants';
import { useProfile } from './useProfile';
import { useUserLogSocketEmitter } from './useSingleSocketEmitter';
import { useGetLoginUserInfoSocket } from './useSocketListener';
import { logoutStore } from 'store/userReducer';
import { useDispatch } from 'react-redux';

const useAuth = () => {
  const { userProfile, setUserProfile } = useProfile();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const { getLoginUserInfoEmitter, userDisconnect } = useUserLogSocketEmitter();
  const getLoginUserInfoListener = useGetLoginUserInfoSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userProfile) {
      // setIsAuthenticated(true);
    }
  }, [userProfile]);

  return {
    getLoginUserInfo: () => {
      const storedToken = Cookies.get(STORAGE.ACCESS_TOKEN);
      if (storedToken) {
        // Firstly - get stored data from local storage and use this.
        let storedData: any = localStorage.getItem(STORAGE.CURRENT_USER);
        if (storedData) {
          storedData = JSON.parse(storedData);
          setUserProfile(storedData.data.user);
        }

        // Second - get updated user data from api and then update with this updated info.
        getLoginUserInfoEmitter(storedToken);
        getLoginUserInfoListener((userInfo: any) => {
          setUserProfile(userInfo.data.user);
          storeCredentials(userInfo);
        });
      }
    },
    setAuthInfoToBrowserStorage: (data: any) => {
      // Note - Cookies will set from backend.
      storeCredentials(data);
    },

    logout: async () => {
      await logout();
      userDisconnect();
      setUserProfile(null);
      dispatch(logoutStore(null));
    },
    
    isAuthenticated: Cookies.get(STORAGE.ACCESS_TOKEN) ? true : false,
  };
};

export default useAuth;
