import React from 'react';
import { Oval } from 'react-loader-spinner';

function SpinLoadingScreen() {
  return (
    <section className="w-screen h-screen bg-[#222] flex justify-center items-center">
      <Oval
        height={40}
        width={40}
        color="#2563eb"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#1e3a8a"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </section>
  );
}

export default SpinLoadingScreen;
