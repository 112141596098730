import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setSocketNeedToSync } from 'store/appReducer';

function useNetworkConnHandler() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [toastShowed, setToastShowed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen for online and offline events when the component mounts
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      dispatch(setSocketNeedToSync(false));
      toast.error('Network connection lost');
      setToastShowed(true);
    }
    if (isOnline) {
      dispatch(setSocketNeedToSync(true));
      if (toastShowed) {
        toast.success('You are reconnected');
        setToastShowed(false);
      }
    }
  }, [isOnline]);
}

export default useNetworkConnHandler;
