import { useState } from "react";
import { InputHTMLAttributes } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    label: string;
    name: string;
    register: any;
    errors: any;
    forgotPassword?: boolean;
    validationSchema: {
        required: boolean | string;
    };
}

function InputPassword({placeholder, label, name, register, errors, forgotPassword, validationSchema}:Props) {

    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    const handleToggleShowPassword = ():void => {
        setIsShowPassword(!isShowPassword);
    }

    return ( 
        <>
        <div>

            <div className="flex justify-between items-center">
                <label htmlFor={name} className="block mb-1 text-text text-xl">{label}</label>

                {forgotPassword && <div className="text-xs transition ease-in-out cursor-pointer hover:text-primary">forgot password?</div>}
            </div>

            <div className="relative">
                <input 
                type={isShowPassword ? "text" : "password"} 
                placeholder={placeholder} 
                id={name} 
                {...register(name, validationSchema)}
                className="w-full py-2 pl-2 pr-12 outline-none rounded-sm transition ease-in-out border-[1px] border-[gray] focus:border-primary"  />
            
                <div onClick={handleToggleShowPassword} className="w-12 h-full absolute top-0 right-0 flex items-center justify-center cursor-pointer">
                    {isShowPassword ? <AiOutlineEye className="text-primary w-6 h-6" /> : <AiOutlineEyeInvisible className="text-primary w-6 h-6" />}
                </div>
            </div>
        </div>

        {errors[name] && <div className="text-xs text-[red]">{errors[name].message}</div>}
        </>
    );
}

export default InputPassword;