import React from 'react';
import { ReactComponent as Logo_2 } from '../../../assets/TeaChat_logo2.svg';

function LogoLoadingScreen() {
  return (
    <section className="w-screen h-screen flex justify-center items-center">
      <Logo_2 className="w-48" />
    </section>
  );
}

export default LogoLoadingScreen;
