import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from 'models/CurrentChatModel.type';
import { GroupChatMessage } from 'models/CurrentGroupChatModel.type';
import { MESSAGE_FLAG } from 'utils/constants';

export interface MediaPayload {
  createdAt: string;
  file_size: number;
  file_upload: string;
  group_id?: string;
  receiver_id?: string;
  message: string;
  sender_id: string;
  updatedAt: string | Date;
  _id: string;
  alsoBuddy?: '1' | '0';
  dateGroup?: string;
}

interface groupAttachedFile {
  groupMedia: MediaPayload[];
  allGroupMediaLoaded: boolean;
  groupFiles: MediaPayload[];
  allGroupFileLoaded: boolean;
  groupLinks: MediaPayload[];
  allGroupLinkLoaded: boolean;
  shareGroupFile: MediaPayload | null;
}

const initialState: groupAttachedFile = {
  groupMedia: [],
  allGroupMediaLoaded: false,
  groupFiles: [],
  allGroupFileLoaded: false,
  groupLinks: [],
  allGroupLinkLoaded: false,
  shareGroupFile: null,
};

export const groupAttachedFileSlice = createSlice({
  name: 'groupAttachedFile',
  initialState,
  reducers: {
    setGroupMedia: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupMedia = action.payload;
    },
    addGroupMedia: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupMedia.push(...action.payload);
    },
    removeGroupMedia: (
      state,
      { payload }: PayloadAction<{ _id: string }[]>
    ) => {
      payload.forEach((payloadMedia) => {
        const mediaIndex = state.groupMedia.findIndex(
          (gpMedia) => gpMedia._id === payloadMedia._id
        );
        if (mediaIndex >= 0) state.groupMedia.splice(mediaIndex, 1);
      });
    },
    setGroupMediaLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allGroupMediaLoaded = action.payload;
    },
    setGroupFiles: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupFiles = action.payload;
    },
    addGroupFiles: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupFiles.push(...action.payload);
    },
    setGroupFileLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allGroupFileLoaded = action.payload;
    },
    setGroupLinks: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupLinks = action.payload;
    },
    addGroupLinks: (state, action: PayloadAction<MediaPayload[]>) => {
      state.groupLinks.push(...action.payload);
    },
    setGroupLinkLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allGroupLinkLoaded = action.payload;
    },
    setShareGroupFileState: (
      state,
      action: PayloadAction<MediaPayload | null>
    ) => {
      state.shareGroupFile = action.payload;
    },
  },
});

export const {
  setGroupMedia,
  addGroupMedia,
  removeGroupMedia,
  setGroupMediaLoadedState,
  setGroupFiles,
  addGroupFiles,
  setGroupFileLoadedState,
  setGroupLinks,
  addGroupLinks,
  setGroupLinkLoadedState,
  setShareGroupFileState,
} = groupAttachedFileSlice.actions;
export default groupAttachedFileSlice.reducer;
