import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { SettingState, setNotificationStatusForAppSetting, setOnlineStatusForMyAccount, setWelcomeActiveStatus } from 'store/settingReducer';

const useSetting = () => {
  const setting : SettingState = useSelector((state: RootState) => state.setting);
  const dispatch = useDispatch();

  return {
    setWelcomeActiveStatus: function (status: boolean) {
      dispatch(setWelcomeActiveStatus(status));
    },
    setNotificationStatusForAppSetting: function (status: boolean) {
      dispatch(setNotificationStatusForAppSetting(status));
    },
    setOnlineStatusForMyAccount: function (status: boolean) {
      dispatch(setOnlineStatusForMyAccount(status));
    },
    setting,
  };
};

export default useSetting;
