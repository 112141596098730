interface FileTypeConfig {
  label: string;
  mimeTypes: string[];
}

const FILE_TYPES_CONFIG: Record<string, FileTypeConfig> = {
  image: {
    label: 'Image',
    mimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  },

  document: {
    label: 'Document',
    mimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'application/zip',
      'application/x-zip-compressed',
      'application/vnd.rar',
      'text/plain',
      'text/csv',
      'application/vnd.android.package-archive',
    ],
  },

  video: {
    label: 'Video',
    mimeTypes: [
      'video/mp4',
      'video/quicktime',
      'video/x-ms-wmv',
      'video/webm',
      'video/x-msvideo',
      'video/x-flv',
      'video/x-matroska',
      'video/MP2T',
      'audio/mp4',
      'video/3gpp',
    ],
  },
  audio: {
    label: 'Audio',
    mimeTypes: [
      'audio/aac',
      'audio/midi',
      'audio/mpeg',
      'audio/ogg',
      'audio/wav',
    ],
  },
};

export const ALLOWED_IMAGE_EXTENSIONS = [
  '.jpg',
  '.png',
  '.gif',
  '.bmp',
  '.jpeg',
  '.webp',
];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  '.doc',
  '.docx',
  '.pdf',
  '.txt',
  '.rtf',
  '.odt',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.csv',
  '.zip',
  '.rar',
  '.apk',
];

export const ALLOWED_VIDEO_EXTENSIONS = [
  '.mp4',
  '.mov',
  '.wmv',
  '.webm',
  '.avi',
  '.flv',
  '.mkv',
  '.mts',
  '.m4a',
  '.3gp',
];

export const ALLOWED_AUDIO_EXTENSIONS = [
  '.aac',
  '.midi',
  '.mp3',
  '.ogg',
  '.wav',
];

export default FILE_TYPES_CONFIG;
