export function removePrefixString(subject: string, prefix: string) {
  if (subject.startsWith(prefix)) {
    return subject.substring(prefix.length); // Removes the first 8 characters ('https://')
  } else {
    return subject;
  }
}

export function removeSubstring(
  targetString: string,
  substringToRemove: string,
  caseSensitive = true
) {
  // Use the replace method with a regular expression to remove the substring
  targetString = caseSensitive ? targetString : targetString.toLowerCase();
  const updatedString = targetString.replace(
    new RegExp(substringToRemove, 'g'),
    ''
  );
  return updatedString;
}
