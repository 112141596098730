import { ReactNode } from 'react';

interface Props {
  title: string;
  fullWidth?: boolean;
  action?: () => void;
  type: 'button' | 'submit' | 'reset';
  styles?: string;
}

export function BtnFilled({ title, action, fullWidth, type, styles }: Props) {
  return (
    <button
      type={type}
      onClick={action}
      className={`${
        fullWidth ? 'w-full' : 'px-2'
      } ${styles} py-2 cursor-pointer transition ease-in-out border border-[transparent] bg-primary text-white hover:bg-primary-dark-0 hover:text-[white] rounded-md flex items-center justify-center`}
    >
      {title}
    </button>
  );
}

export function BtnOutlined({ title, action, fullWidth, type }: Props) {
  return (
    <button
      type={type}
      onClick={action}
      className={`${
        fullWidth ? 'w-full' : 'px-2'
      } py-2 cursor-pointer transition ease-in-out border border-primary bg-[white] text-primary hover:bg-secondary2 rounded-md flex items-center justify-center`}
    >
      {title}
    </button>
  );
}

interface IconProps {
  action: () => void;
  icon: ReactNode;
  type: 'button' | 'submit' | 'reset';
}

export function BtnIcon({ action, icon, type }: IconProps) {
  return (
    <button type={type} onClick={action}>
      {icon}
    </button>
  );
}
