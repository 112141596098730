interface Props {
  width?: string;
  height?: string;
}

function Logo({ width, height }: Props) {
  return (
    <div className={`${width} ${height}`}>
      <img
        alt="teatalk"
        src="/images/logo.svg"
        className={`${width} ${height} object-contain`}
      />
    </div>
  );
}

export default Logo;
