import useAuth from 'hooks/useAuth';
import { useProfile } from 'hooks/useProfile';
import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';
import AppSetupProvider from './AppSetupProvider';
import NotiToaster from 'components/common/ReactHotToast/NotiToaster';

function AuthProvider({ children }: PropsWithChildren): JSX.Element {
  const { isAuthenticated, getLoginUserInfo } = useAuth();
  const { userProfile } = useProfile();
  const navigate = useNavigate();

  // checking for stored user info in browser storage
  useEffect(() => {
    if (!userProfile) {
      console.log('AuthProvider');
      getLoginUserInfo();
    }
  }, []);

  // goto login if current user is not authenticated user.
  useEffect(() => {
    if (isAuthenticated !== null) {
      !isAuthenticated && navigate('login');
    }
  }, [isAuthenticated]);

  return (
    <AppSetupProvider>
      {children}
      <NotiToaster />
    </AppSetupProvider>
  );
}

export default AuthProvider;
