import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';

const useCurrentGroupChat = () => {
  const currentGroupChat = useSelector(
    (state: RootState) => state.currentGroupChat
  );

  return currentGroupChat;
};

export default useCurrentGroupChat;
