import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface viewContactListToForwardState {
    viewContactListStatus: boolean;
}

const initialState: viewContactListToForwardState = {
  viewContactListStatus: false
}

export const viewContactListToForwardSlice = createSlice({
  name: 'viewContactListToForward',
  initialState,
  reducers: {
    setViewContactListStatus: (state, action: PayloadAction<any>) => {
      state.viewContactListStatus = action.payload
    }
  },
})

export const { setViewContactListStatus } = viewContactListToForwardSlice.actions

export default viewContactListToForwardSlice.reducer