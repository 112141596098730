import '../css/utils.css';
import '../css/scrollBarStyle.css';
import '../css/audioStyle.css';
import { useEffect, PropsWithChildren, useState } from 'react';
import useSocketListener from 'hooks/useSocketListener';
import { requestNotificationApi } from 'utils';
import useGroupSocketListener from 'hooks/useGroupSocketListener';
import { useUserJoinSocket } from 'hooks/useSingleSocketEmitter';
import useNetworkConnHandler from 'hooks/useNetworkConnHandler';
import useWindowResizeHandler from 'hooks/useWindowResizeHandler';
import useSetupCallTimer from 'hooks/useSetupCallTimer';

function AppSetupProvider({ children }: PropsWithChildren) {
  useUserJoinSocket();
  useSocketListener();
  useGroupSocketListener();
  useNetworkConnHandler();
  useWindowResizeHandler();
  useSetupCallTimer();

  useEffect(() => {
    requestNotificationApi();
  }, []);

  return <>{children}</>;
}

export default AppSetupProvider;
