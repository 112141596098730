import FILE_TYPES_CONFIG, {
  ALLOWED_AUDIO_EXTENSIONS,
  ALLOWED_DOCUMENT_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_VIDEO_EXTENSIONS,
} from 'utils/FileTypeConfig';
import { STORAGE } from './constants';
import Cookies from 'js-cookie';

export const detectMobile = () => {
  return window.innerWidth < 768;
};

export const isWithLink = (message: string) => {
  return (
    new RegExp(
      '(http|https)://([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
    ).test(message) ||
    new RegExp(
      '(http|https)://([0-9]{3}|[0-9]{2}|[0-9]{1}).([0-9]{3}|[0-9]{2}|[0-9]{1}).([0-9]{3}|[0-9]{2}|[0-9]{1}).([0-9]{3}|[0-9]{2}|[0-9]{1})(:[0-9]+)?(/.*|)?'
    ).test(message)
  );
};

// export const isValidUrl = (urlString: string) => {
//   var urlPattern = new RegExp(
//     '^(https?:\\/\\/)?' + // validate protocol
//       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
//       '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
//       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
//       '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
//       '(\\#[-a-z\\d_]*)?$',
//     'i'
//   ); // validate fragment locator
//   return !!urlPattern.test(urlString);
// };

export const isValidUrl = (urlString: string) => {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return !!urlPattern.test(urlString);
};

export const textHasUrl = (text: string) => {
  const domainRegex =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/; //Extract url from text

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator

  const match = text.match(domainRegex);

  if (match && match[0]) {
    return !!urlPattern.test(match[0]);
  } else {
    return false;
  }
};

export function extractUrlFromText(text: string, includePrefix = true) {
  const domainRegex =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;

  const match = text.match(domainRegex);

  let matchedUrl: string;
  if (match && match[0]) {
    matchedUrl = match[0];
  } else {
    return null;
  }

  if (!includePrefix) {
    return matchedUrl;
  } else if (
    !matchedUrl.startsWith('http://') &&
    !matchedUrl.startsWith('https://')
  ) {
    return 'https://' + matchedUrl;
  } else return matchedUrl;
}

export const isWithGifLink = (message: string) => {
  return new RegExp(
    '(http|https)://([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?\\.gif'
  ).test(message);
};

export const requestNotificationApi = () => {
  try {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((res) => {
        const statusInStorage = sessionStorage.getItem(
          STORAGE.NOTI_PERMISSION_STATUS
        );
        if (res === 'denied' && !statusInStorage) {
          sessionStorage.setItem(STORAGE.NOTI_PERMISSION_STATUS, 'denied');
          alert(
            'You will need to enable in browser setting to get notification.'
          );
        }
      });
    }
  } catch (error) {
    alert('Your device does not support browser notification');
  }
};

export const notifyInBrowser = (id: string, msg: string) => {
  const notiMsgId = localStorage.getItem(STORAGE.NOTI_MSG_ID);

  if (notiMsgId !== id) {
    localStorage.setItem(STORAGE.NOTI_MSG_ID, id);
    new Notification(msg);
  }
};

export const emitNotification = ({
  is_muted,
  sender_name,
  sender_image,
  message,
  file_upload,
  image,
}: {
  is_muted: boolean;
  sender_name?: string;
  sender_image?: string;
  file_upload?: string;
  message: string;
  image?: string;
}) => {
  console.log('notification fire');

  const notification = new Notification(`${sender_name}`, {
    tag: sender_name,
    icon: sender_image,
    // image: file_upload,
    body: message,
    silent: false,
    renotify: true,
  });

  console.log(notification);
  console.log(Notification.permission);

  notification.onclick = function () {
    window.focus();
  };
};

export const isValidImageFile = (fileName: string) => {
  if (!fileName) return;
  const fileExtension: string = fileName.slice(fileName.lastIndexOf('.'));

  return ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension);
};

export const isValidVideoFile = (fileName: string) => {
  if (!fileName) return;

  const fileExtension: string = fileName.slice(fileName.lastIndexOf('.'));

  return ALLOWED_VIDEO_EXTENSIONS.includes(fileExtension);
};

export const isValidAudioFile = (fileName: string) => {
  if (!fileName) return;

  const fileExtension: string = fileName.slice(fileName.lastIndexOf('.'));

  return ALLOWED_AUDIO_EXTENSIONS.includes(fileExtension);
};

export const isValidDocumentFile = (fileName: string) => {
  if (!fileName) return;

  const fileExtension: string = fileName.slice(fileName.lastIndexOf('.'));

  return ALLOWED_DOCUMENT_EXTENSIONS.includes(fileExtension);
};

export const getWhichFileType = (file: File) => {
  const mimeType = file.type;
  console.log(mimeType);
  const fileType = Object.values(FILE_TYPES_CONFIG).find((fileType) =>
    fileType.mimeTypes.includes(mimeType)
  );

  return fileType;
};

export const formatBytes = (bytes: number) => {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;

  if (megabytes >= 1) {
    return megabytes.toFixed(1) + ' MB';
  } else {
    return kilobytes.toFixed(1) + ' KB';
  }
};

export const getFileFromBase64String = (base64String: any) => {
  const byteString = atob(base64String);
  const mimeString = 'image/jpeg'; // change the mime type if needed
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  return { blob };
};

export const extractGifUrlsFromStringWithGifUrl = (str: string) => {
  // Regular expression to match URL ending with .gif extension
  const gifRegex = /(https?:\/\/\S+\.gif)/;
  const globalGifRegex =
    /(http|https):\/\/([a-zA-Z0-9.-]+\/)*[a-zA-Z0-9_-]+\.gif/g;
  const urls = str.match(globalGifRegex);
  return { urls, gifRegex, globalGifRegex };
};

export const clearAllCookies = () => {
  const cookies = Cookies.get(); // Get all cookies
  console.log(cookies);
  // Iterate over each cookie and remove it
  Object.keys(cookies).forEach((cookie) => Cookies.remove(cookie));
};

export const downloadFile = (fileName: string, url?: string) => {
  const link = document.createElement('a');
  link.href = url
    ? url
    : `${process.env.REACT_APP_RESOURCE_FILE_BASE_URL}/${fileName}`;
  link.download = fileName;
  link.click();
};
