import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { JitsiMeeting } from '@jitsi/react-sdk';
import { JitsiPayload, localCallDataType } from 'models/DialingStateModel.type';
import { useUserData } from 'hooks/useProfile';
import { removePrefixString } from 'helpers/removeString';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import useJitsiEventHandlers from 'hooks/useJitsiEventHandlers';
import { useWebSocket } from 'hooks/useWebSocket';
// import SpinLoadingScreen from 'components/common/LoadingScreen/SpinLoadingScreen';
import { Oval } from 'react-loader-spinner';
import { CALL_TYPE } from 'utils/constants';
import SpinLoadingScreen from 'components/common/LoadingScreen/SpinLoadingScreen';
import { useSendMessageSockets } from 'hooks/useSingleSocketEmitter';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useCallEnd } from 'hooks/useCallSocketEmitter';
import { useSearchParams } from 'react-router-dom';
import { useGroupCallEnd } from 'hooks/useGroupCallSocketEmitter';

// import  Svg from '../../../../public/images/logoImg.svg'

function JitsiCall({
  username,
  userId,
  userImage,
  subjectName,
  subjectId,
  groupName,
  groupId,
  callFromUserId,
  callType,
  callConfig,
  jitsi,
  callParam,
}: {
  callParam: string;
} & localCallDataType) {
  const apiRef = useRef<IJitsiMeetExternalApi>();
  const callEnd = useCallEnd();
  const groupCallEnd = useGroupCallEnd();

  console.log(callParam);
  useEffect(() => {
    apiRef.current?.executeCommand(
      'avatarUrl',
      `${process.env.REACT_APP_MEDIA_FILE_BASE_URL}/${userImage}`
    );
  }, [apiRef, userImage]);

  useEffect(() => {
    window.focus();
    const handleUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
      if (callParam === 'one_to_one') {
        callEnd({ callEndedTo: subjectId!, callEndedBy: userId, callType });
      }
      if (callParam === 'group') {
        groupCallEnd({
          groupId: groupId!,
          callFromUserId: callFromUserId!,
          callType,
        });
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [callEnd, subjectId, userId, callType]);

  const toolbarClickHandler = (
    payload: any
    //   : {
    //   key: string;
    //   preventExecution: boolean;
    // }
  ) => {
    console.log(payload);
    // if (key === 'hangup') callEndHandler({ subjectId, callType });
  };

  const handleApiReady = (externalApi: any) => {
    apiRef.current = externalApi;
    if (!apiRef) return;
    apiRef.current?.on('toolbarButtonClicked', (payload) =>
      console.log('Toolbar clicked', payload)
    );
    apiRef.current?.on('participantLeft', (payload) => {
      console.log('participantLeft', payload);
      if (callParam === 'one_to_one') {
        callEnd({ callEndedTo: subjectId!, callEndedBy: userId, callType });
      }
      if (callParam === 'group') {
        groupCallEnd({
          groupId: groupId!,
          callFromUserId: callFromUserId!,
          callType,
        });
      }
    });
    apiRef.current?.on('participantJoined', (payload) => {
      console.log('participantJoined', payload);
    });
  };

  return (
    <section className="w-screen h-screen">
      <JitsiMeeting
        domain={removePrefixString(jitsi.externalApi, 'https://')}
        roomName={jitsi.roomName}
        spinner={SpinLoadingScreen}
        configOverwrite={{
          startWithAudioMuted: callConfig.micOff,
          startWithVideoMuted: callConfig.cameraOff,
          disableModeratorIndicator: true,
          // startScreenSharing: true,
          enableEmailInStats: false,
          prejoinConfig: {
            enabled: false,
          },
          logoImageUrl: '../../../../public/images/logo.svg',
          toolbarButtons: [
            'camera',
            'hangup',
            'microphone',
            'toggle-camera',
            'fullscreen',
            // 'participants-pane',
            // 'profile',
            // 'desktop',
            // 'shareaudio',
            // 'sharedvideo',
            // 'noisesuppression',
            // 'chat',
            // 'closedcaptions',
            // 'download',
            // 'embedmeeting',
            // 'etherpad',
            // 'feedback',
            // 'filmstrip',
            // 'help',
            // 'highlight',
            // 'invite',
            // 'linktosalesforce',
            // 'livestreaming',
            // 'raisehand',
            // 'recording',
            // 'security',
            // 'select-background',
            // 'settings',
            // 'shortcuts',
            // 'stats',
            // 'tileview',
            // 'videoquality',
            // 'whiteboard',
          ],
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
          HIDE_DEEP_LINKING_LOGO: true,
          HIDE_INVITE_MORE_HEADER: true,
          MOBILE_APP_PROMO: false,
          SHOW_JITSI_WATERMARK: false,
          DEFAULT_LOGO_URL: '../../../../public/images/logo.svg',
        }}
        userInfo={{
          displayName: username,
          email: '',
        }}
        onApiReady={(externalApi) => {
          handleApiReady(externalApi);
          console.log(externalApi);
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.width = '100%';
          iframeRef.style.height = '100%';
        }}
      />
    </section>
  );
}

export default JitsiCall;
