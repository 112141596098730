import { logoutApi } from 'queries/auth';
import { STORAGE } from './constants';
import Cookies from 'js-cookie';
import { clearAllCookies } from 'utils';

export const logout = async () => {
  await logoutApi();
  localStorage.clear();
  sessionStorage.clear();
  clearAllCookies();
};

export const storeCredentials = (data: any) => {
  let credentials: any = localStorage.getItem(STORAGE.CURRENT_USER);

  console.log(data.cookies);

  if (data.cookies) {
    if (Array.isArray(data.cookies)) {
      data.cookies.forEach((cookie: any) => {
        if (cookie.domain === '.teatalk.io' || cookie.domain === 'teatalk.io')
          return;
        Cookies.set(cookie.key, cookie.value);
      });
    }
  }

  if (data.token) {
    credentials = data;
  } else {
    if (credentials) {
      credentials = JSON.parse(credentials);
    }
    if (credentials?.data) {
      credentials.data = data;
    }
  }

  if (credentials) {
    if (!credentials.data.data) {
      if (credentials.data.user) {
        const data = {
          ...credentials,
          data: {
            data: credentials.data,
          },
        };
        localStorage.setItem(STORAGE.CURRENT_USER, JSON.stringify(data));
      }
    }
  } else {
    // alert('Something went wrong. [Code-001].');
    console.log('No credentials');
  }
};

// export const getCredentials = () => {
//   const storedCredentials = localStorage.getItem(STORAGE.CURRENT_USER);

//   let credentials = storedCredentials ? JSON.parse(storedCredentials) : {};
//   console.log(credentials);

//   return credentials;
// };

export const getCredentials = () => {
  let token = Cookies.get(STORAGE.ACCESS_TOKEN);
  return { token };
  // return token ? JSON.parse(token) : null;
};
