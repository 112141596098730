import { PayloadAction } from '@reduxjs/toolkit';
import {
  Contact,
  RoomGroup,
  RoomUser,
  Typing,
} from 'models/AppStateModel.type';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { AppState } from 'store/appReducer';

const useAppState = () => {
  const appState: AppState = useSelector((state: RootState) => state.app);

  return {
    appState,
  };
};

export default useAppState;
