import useAuth from "hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

function NotRequiredAuth({children}: {children: JSX.Element}) {
    const { isAuthenticated } = useAuth()
    const location = useLocation();
    

    if(isAuthenticated) {
        return ( 
            <Navigate to="/" state={{from: location}} replace />
        );
    }

    return children;
}

export default NotRequiredAuth;