import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientWindow {
  windowWidth: number;
  windowHeight: number;
}

const initialState: ClientWindow = {
  windowWidth: 0,
  windowHeight: 0,
};

const clientWindowSlice = createSlice({
  name: 'clientWindow',
  initialState,
  reducers: {
    setWindowWidth: (state, action: PayloadAction<number>) => {
      state.windowWidth = action.payload;
    },
    setWindowHeight: (state, action: PayloadAction<number>) => {
      state.windowHeight = action.payload;
    },
  },
});

export const { setWindowWidth, setWindowHeight } = clientWindowSlice.actions;

export default clientWindowSlice.reducer;
