import { Contact, RoomGroup, RoomUser } from 'models/AppStateModel.type';
import { ChatMessage } from 'models/CurrentChatModel.type';
import { useEffect, useRef } from 'react';
import { logout } from 'utils/auth';
import {
  CALL_TYPE,
  CHAT_MESSAGE_STATE,
  MESSAGE_FLAG,
  STORAGE,
} from 'utils/constants';
import useAppState from './useAppState';
import useCurrentChat from './useCurrentChat';
import { useProfile, useUserData } from './useProfile';
import { useWebSocket } from './useWebSocket';
import { emitNotification, notifyInBrowser } from 'utils';
import useSetting from './useSetting';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToOnlineContacts,
  removeFromOnlineContacts,
  setSocketNeedToSync,
  updateUserInRoomUsers,
  updateContactInContacts,
  setContactList,
  setOnlineContactList,
  setRoomUserList,
  setRoomGroupList,
  setTypings,
  updateLastActivityOnetoOneChat,
  updateOneToOneLists,
} from 'store/appReducer';
import {
  setCallRinging,
  setCallRingingTo,
  setCurrentlyOnCall,
} from 'store/dialingReducer';
import useDialingState from './useDialingState';
import { useCallBusy } from './useCallSocketEmitter';
import {
  addPaginatedMessage,
  editOneToOneMessage,
  populateMessages,
  pushMessage,
  setLoadingContactStatus,
  setLoadingOneToOneMsgStatus,
  setTotalMessageCount,
  updateOneToOneDeletedMessage,
  updateOnetoOneMsg,
} from 'store/currentChatReducer';
import { setTypingOneToOneMember } from 'store/messageTargetReducer';
import { updateCurrentGroupMemberStatus } from 'store/currentGroupChatReducer';
import useCallDisconnectHandler from './useCallDisconnectHandler';
import { RootState } from 'store';
import {
  useMessageSeenEmitter,
  useSearchFriends,
} from './useSingleSocketEmitter';

const useSocketListener = () => {
  const socket = useWebSocket();
  const dispatch = useDispatch();
  const { setUserProfile } = useProfile();
  const { userId } = useUserData();
  const { setCurrentChatContact, setIsNeedToSyncNow, currentChat } =
    useCurrentChat();

  const { appState } = useAppState();
  const {
    setWelcomeActiveStatus,
    setNotificationStatusForAppSetting,
    setOnlineStatusForMyAccount,
  } = useSetting();
  const accountNotifiactionEnabled = useSelector(
    (state: RootState) => state.setting.status.isEnableNotificationForAppSetting
  );
  const { readMessage } = useMessageSeenEmitter();

  const { currentlyOnCall, callRinging, callRingingTo } = useDialingState();
  const callBusy = useCallBusy();

  const onlineTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { handleCallDisconnect } = useCallDisconnectHandler();
  const { searchForChatAllFriends, searchForChatActiveFriends } =
    useSearchFriends();
  // SOCKETs WITH NO DEPENDENCY
  useEffect(() => {
    if (!appState.socketNeedToSync) return;

    socket.on('invalidToken', async () => {
      await logout();
      setUserProfile(null);
    });

    socket.on('disconnect', () => {
      dispatch(setSocketNeedToSync(false));
    });

    socket.on('welcome-message', () => {
      setWelcomeActiveStatus(true);
    });

    socket.on(
      'contactsLists',
      async ({ contacts }: { contacts: Contact[] }) => {
        console.log('contactsLists ', contacts);
        dispatch(setContactList(contacts));
      }
    );

    socket.on(
      'onlineBuddyLists',
      async ({ contacts }: { contacts: Contact[] }) => {
        console.log('onlineBuddyLists', contacts);
        dispatch(setOnlineContactList(contacts));
      }
    );

    socket.on(
      'roomUsers',
      ({ users, groups }: { users: RoomUser[]; groups: RoomGroup[] }) => {
        const arr = [...users];

        socket.off('roomUsers');

        console.log('users ::', users);
        console.log('groups ::', groups);

        arr.sort((a: RoomUser, b: RoomUser) => {
          const f: any = new Date(b.msg_createdAt).getTime();
          const l: any = new Date(a.msg_createdAt).getTime();

          return f - l;
        });

        groups?.sort((a: RoomGroup, b: RoomGroup) => {
          let aDate = a.lastMessage?.createdAt || a.createdAt;
          let bDate = b.lastMessage?.createdAt || b.createdAt;

          const f: any = new Date(bDate).getTime();
          const l: any = new Date(aDate).getTime();

          return f - l;
        });

        dispatch(setRoomUserList(arr));
        dispatch(setRoomGroupList(groups));
      }
    );

    socket.on('client-notistatus-update', ({ is_muted }: { is_muted: any }) => {
      const data = localStorage.getItem(STORAGE.CURRENT_USER);
      const storedData = data ? JSON.parse(data) : null;
      if (storedData) {
        storedData.data.data.user.notification = is_muted;
        localStorage.setItem(STORAGE.CURRENT_USER, JSON.stringify(storedData));
      }
      console.log('is_muted::', is_muted);
      setNotificationStatusForAppSetting(is_muted == '1' ? true : false);
    });

    socket.on('client-userbusy-update', ({ user_busy }: { user_busy: any }) => {
      const data = localStorage.getItem(STORAGE.CURRENT_USER);
      const storedData = data ? JSON.parse(data) : null;
      if (storedData) {
        storedData.data.data.user.user_busy = user_busy;
        localStorage.setItem(STORAGE.CURRENT_USER, JSON.stringify(storedData));
      }
      console.log('user_busy', user_busy);
      setOnlineStatusForMyAccount(user_busy == '0' ? true : false); // 0 means that user is not busy now.
    });

    socket.on('contactInfo', (data) => {
      console.log('contactInfo', data);
      if (data.contacts && data.contacts.length && data.contacts.length === 1) {
        setCurrentChatContact(data.contacts[0]);
      }
    });

    return () => {
      socket.off('invalidToken');
      socket.off('disconnect');
      socket.off('welcome-message');
      socket.off('contactsLists');
      socket.off('onlineBuddyLists');
      socket.off('roomUsers');
      socket.off('client-notistatus-update');
      socket.off('client-userbusy-update');
      socket.off('contactInfo');
    };
  }, [socket, appState.socketNeedToSync]);

  useEffect(() => {
    if (!appState.socketNeedToSync) return;

    socket.on('user-connected', (user_id) => {
      console.log('user-connected :: ', user_id);

      // cancel previous online status update, if the disconnected user reconnect within the timeout range (30sec)
      if (onlineTimeoutRef.current !== null) {
        clearTimeout(onlineTimeoutRef.current);
      }

      searchForChatActiveFriends('');
      searchForChatAllFriends('');

      dispatch(updateCurrentGroupMemberStatus({ user_id, is_active: true }));

      if (currentChat.contact) {
        if (user_id === currentChat.contact.receiver_id)
          setCurrentChatContact({
            ...currentChat.contact,
            active: true,
          });
      }

      const newActiveUser = appState.list.contacts.find(
        (contact) => contact.receiver_id === user_id
      );

      newActiveUser &&
        dispatch(
          addToOnlineContacts({
            ...newActiveUser,
            active: true,
            last_active_date: new Date().toLocaleString(),
          })
        );

      dispatch(updateContactInContacts({ receiver_id: user_id, active: true }));

      dispatch(
        updateUserInRoomUsers({
          receiver_id: user_id,
          is_active: true,
        })
      );
    });

    socket.on('user-disconnected', (user_id) => {
      console.log('user-disconnected::', user_id);

      // Clear out previous online status update timeout
      if (onlineTimeoutRef.current !== null) {
        clearTimeout(onlineTimeoutRef.current);
      }

      // If disconnected user is active on another browser/tab, send back active status
      if (user_id === userId) {
        setTimeout(() => {
          socket.emit('new-user-joined', { userId, userBusy: '0' });
        }, 500);
      }

      // Update active status
      onlineTimeoutRef.current = setTimeout(() => {
        // update active status on current chatting panel
        if (currentChat.contact) {
          if (user_id === currentChat.contact.receiver_id)
            setCurrentChatContact({
              ...currentChat.contact,
              active: false,
              last_active_date: new Date().toLocaleString(),
            });
        }

        dispatch(removeFromOnlineContacts(user_id));

        dispatch(
          updateContactInContacts({
            receiver_id: user_id,
            active: false,
          })
        );

        dispatch(
          updateUserInRoomUsers({
            receiver_id: user_id,
            is_active: false,
          })
        );

        searchForChatAllFriends('');
        searchForChatActiveFriends('');
      }, 15000);

      // update current group active member status
      dispatch(updateCurrentGroupMemberStatus({ user_id, is_active: false }));

      // update typing status on user disconnect
      dispatch(
        updateUserInRoomUsers({
          receiver_id: user_id,
          lastTypingMember: null,
        })
      );
      if (user_id === currentChat.contact?.receiver_id) {
        dispatch(setTypingOneToOneMember([]));
      }

      // Call end on user disconnect
      handleCallDisconnect({ disconnected_userId: user_id });
    });

    socket.on('unknownErrors', (data) => {
      console.log(data);
    });

    return () => {
      socket.off('user-connected');
      socket.off('user-disconnected');
      socket.off('unknownErrors');
    };
  }, [
    socket,
    userId,
    appState.list,
    appState.socketNeedToSync,
    currentChat.contact,
    callRinging,
    callRingingTo,
    currentlyOnCall,
  ]);

  useEffect(() => {
    if (!appState.socketNeedToSync) return;

    socket.on('all_Message_delete', (data) => {
      console.log('all_Message_delete', data, userId);

      if (data.receiverId === userId && data.alsoBuddy) {
        dispatch(updateOneToOneLists({ receiver_id: data.userId }));
      }
    });

    socket.on('typing', function (data) {
      console.log('typing message', data);

      if (data.senderId === userId) return;
      // Show typing in chat
      if (data?.senderId === currentChat.contact?.receiver_id) {
        if (data?.isTyping) {
          dispatch(
            setTypingOneToOneMember([
              {
                isTyping: data?.isTyping,
                senderId: data?.senderId,
                senderImage: data?.Image,
                senderName: data?.nick,
              },
            ])
          );
        } else {
          dispatch(setTypingOneToOneMember([]));
        }
      }

      // Show typing in chat history card
      if (data.isTyping) {
        dispatch(
          updateUserInRoomUsers({
            receiver_id: data.senderId,
            lastTypingMember: {
              senderId: data.senderId,
              name: data.nick,
            },
          })
        );
      } else {
        dispatch(
          updateUserInRoomUsers({
            receiver_id: data.senderId,
            lastTypingMember: null,
          })
        );
      }
    });

    socket.on('markAsUnread', (data) => {
      console.log('markAsUnread', data);
    });

    socket.on('markAsRead', (data) => {
      console.log('markAsRead', data);
    });

    socket.on('client_contactnoti_update', (data) => {
      console.log('client_contactnoti_update', data);
      dispatch(
        updateUserInRoomUsers({
          receiver_id: data?.receiver_id,
          is_muted: data?.is_muted,
        })
      );
    });

    // for one to  One chat listnerd

    socket.on('chat message', (data) => {
      console.log('chat message (receive) ::', data);
      // console.log('currentchat id:', currentChat.contact?._id);
      const receiverContact = appState.list.roomUsers?.find(
        (oneToOne) => oneToOne.receiver_id === data?.sender_id
      );

      const unreadCount: number =
        currentChat.contact?.receiver_id === data?.myid
          ? 0
          : receiverContact?.unreadCount! + 1;

      dispatch(
        updateUserInRoomUsers({
          receiver_id:
            data.sender_id === userId ? data.receiver_id : data.sender_id,
          message: data.message,
          unreadCount,
          msg_senderid: data.sender_id,
          msg_createdAt: new Date().toLocaleString(),
          lastmsg_id: data.id,
          contact_id: data.contactId,
          file_upload: data.file_upload,
          flag: data.flag,
          flat: data.flag,
          lastTypingMember: null,
        })
      );

      dispatch(updateLastActivityOnetoOneChat({ contact_id: data.contactId }));

      if (data.myid === currentChat.contact?.receiver_id) {
        if (data.sender_id === userId) {
          dispatch(
            updateOnetoOneMsg({
              ...data,
              createdAt: new Date().toLocaleString(),
              replyToMessage: [data.replyToMessage],
              reply_message: [],
              image: [],
              reply_file: [],
              reply_name: [],
              nickName: [],
              name: [data.receiverName],
              _id: data.id,
              contact_id: [data.contactId],
              sending: false,
            })
          );
        } else {
          dispatch(
            pushMessage({
              ...data,
              createdAt: new Date().toLocaleString(),
              replyToMessage: [data.replyToMessage],
              reply_message: [],
              image: [],
              reply_file: [],
              reply_name: [],
              nickName: [],
              name: [data.receiverName],
              _id: data.id,
              contact_id: [data.contactId],
            })
          );
          dispatch(setTypingOneToOneMember([]));
        }

        // Send message read socket if user is currently chatting with the sender
        if (data.sender_id !== userId) readMessage({ messageId: data.id });
      }

      // Push notification
      if (
        data.sender_id !== userId &&
        accountNotifiactionEnabled &&
        receiverContact?.is_muted != 1 &&
        data.flag != MESSAGE_FLAG.AUDIO &&
        data.flag != MESSAGE_FLAG.VIDEO
      ) {
        console.log('notification condition passed');
        emitNotification({
          is_muted: data.is_muted,
          sender_name: data.sender_name,
          sender_image: data.sender_image,
          file_upload: `${process.env.REACT_APP_MEDIA_FILE_BASE_URL}/${data.file_upload}`,
          message: data.message || data.file_upload,
        });
      }
      // setIsNeedToSyncNow(true);
    });

    //  for edit One To One
    socket.on('client_message_update', (data) => {
      console.log('client_mesage_update', data);
      if (data.flag === MESSAGE_FLAG.EDIT) {
        dispatch(
          editOneToOneMessage({
            id: data?.messageId,
            editedMsg: data?.message,
            flag: data.flag,
          })
        );
      }
    });

    // for message Delete
    socket.on('message_delete', (data) => {
      console.log('message_delete', data);
      console.log(currentChat.contact);

      const receiverContact = appState.list.roomUsers?.find(
        (oneToOne) => oneToOne.receiver_id === data?.userId
      );

      if (data.alsoBuddy == 1)
        dispatch(
          updateOneToOneDeletedMessage({
            receiveId: data.receiverId,
            messageId: data.message_id,
            flag: data.flag,
            alsoBuddy: data.alsoBuddy,
          })
        );

      // update chat preview last message
      console.log(receiverContact);
      if (receiverContact?.lastmsg_id == data.message_id) {
        if (data.alsoBuddy != 1) return;
        dispatch(
          updateUserInRoomUsers({
            flag: data.flag,
            flat: data.flag,
            receiver_id: data.userId,
          })
        );
      }
    });

    socket.on('readMessage', (data) => {
      if (data?.msg.receiver_id === currentChat.contact?.receiver_id)
        console.log('readMessage', data);
      dispatch(
        updateOnetoOneMsg({
          _id: data?.msg?._id,
          unread: '1',
        })
      );
    });

    socket.on('isMessage', (data) => {
      console.log('isMessage :: ', data);
    });

    socket.on('client_message_update', function (data) {
      // setIsNeedToSyncNow(true);
      console.log('client_message_update:: ', data);
    });

    return () => {
      socket.off('all_Message_delete');
      socket.off('typing');
      socket.off('unreadMsgUpdate');
      socket.off('client_contactnoti_update');
      socket.off('chat message');
      socket.off('client_message_update');
      socket.off('client_message_delete');
      socket.off('message_delete');
      socket.off('markAsUnread');
      socket.off('markAsRead');
      socket.off('readMessage');
    };
  }, [
    socket,
    userId,
    appState.list,
    appState.socketNeedToSync,
    currentChat.contact,
    accountNotifiactionEnabled,
  ]);

  useEffect(() => {
    if (!appState.socketNeedToSync) return;

    socket.on('callRinging', (data) => {
      console.log('callRinging ::', data);
      if (currentlyOnCall) {
        callBusy({
          callFromUserId: data.callFromUserId,
        });
      } else {
        dispatch(setCurrentlyOnCall(true));
        dispatch(setCallRinging(data));
      }

      if (data.callFromUserId !== userId) {
        emitNotification({
          is_muted: false,
          sender_name: data.callFromUsername,
          sender_image: data.callFromUserImage,
          message:
            data.callType == CALL_TYPE.AUDIO
              ? 'Incoming audio call'
              : 'Incoming video call',
        });
      }
    });

    return () => {
      socket.off('callRinging');
      // socket.off('callEnded');
    };
  }, [socket, appState.socketNeedToSync, currentlyOnCall]);
};

export default useSocketListener;

export function useGetChatLatestMessages() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();
  const { readMessage } = useMessageSeenEmitter();

  const getChatLatestMessages = () => {
    socket.off('userMessage');
    socket.off('chat-pg');

    socket.on(
      'userMessage',
      ({ users, msgno }: { users: ChatMessage[]; msgno: number }) => {
        console.log('userMessage::', { users, msgno });
        // console.log(msgno);
        const messages = users;

        dispatch(populateMessages(messages));
        dispatch(setTotalMessageCount(msgno));
        dispatch(setLoadingContactStatus(false));
        dispatch(setLoadingOneToOneMsgStatus(false));

        // Read all incoming message
        users.forEach((msg) => {
          readMessage({ messageId: msg._id });
        });
      }
    );

    socket.on('chat-pg', ({ users }) => {
      console.log('chat-pg::', { users });
      const messages = users;

      dispatch(addPaginatedMessage(messages));
      dispatch(setLoadingContactStatus(false));
      dispatch(setLoadingOneToOneMsgStatus(false));
    });
  };

  return getChatLatestMessages;
}

export function useGetLoginUserInfoSocket() {
  const socket = useWebSocket();
  const getLoginUserInfoListener = (cb: (userInfo: any) => void) => {
    socket.off('currentUser');
    socket.on('currentUser', function ({ userInfo }) {
      cb(userInfo);
    });
  };

  return getLoginUserInfoListener;
}
