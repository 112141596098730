import {
  configureStore,
  combineReducers,
  PayloadAction,
} from '@reduxjs/toolkit';
import appReducer from './appReducer';
import dialingReducer from './dialingReducer';
import userReducer from './userReducer';
import currentChatReducer from './currentChatReducer';
import currentGroupChatReducer from './currentGroupChatReducer';
import viewMediaReducer from './viewMediaReducer';
import viewContactListToForwardReducer from './viewContactListToForwardReducer';
import viewCameraReducer from './viewCameraReducer';
import viewChatDeleteConfirmationReducer from './viewChatDeleteConfirmationReducer';
import settingReducer from './settingReducer';
import roomReducer from './roomReducer';
import groupChatMemberSelectReducer from './groupChatMemberSelectReducer';
import selectMessageReducer from './selectMessageReducer';
import messageTargetReducer from './messageTargetReducer';
import clientWindowReducer from './clientWindowReducer';
import chatSettingReducer from './chatSettingReducer';
import groupAttachedFileReducer from './groupAttachedFileReducer';
import oneToOneAttachFileReducer from './oneToOneAttachFileReducer';
import groupShareFile from './groupAttachedFileReducer';
// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//     app: appReducer,
//     dialing: dialingReducer,
//     setting: settingReducer,
//     room: roomReducer,
//     currentChat: currentChatReducer,
//     currentGroupChat: currentGroupChatReducer,
//     viewMedia: viewMediaReducer,
//     viewContactList: viewContactListToForwardReducer,
//     viewCamera: viewCameraReducer,
//     viewChatDeleteConfirmation: viewChatDeleteConfirmationReducer,
//     groupChatMemberSelect: groupChatMemberSelectReducer,
//     selectMessageState: selectMessageReducer,
//     messageTargetState: messageTargetReducer,
//     clientWindowState: clientWindowReducer,
//     chatSettingState: chatSettingReducer,
//     groupAttachedFileState: groupAttachedFileReducer,
//   },
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

const combinedReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  dialing: dialingReducer,
  setting: settingReducer,
  room: roomReducer,
  currentChat: currentChatReducer,
  currentGroupChat: currentGroupChatReducer,
  viewMedia: viewMediaReducer,
  viewContactList: viewContactListToForwardReducer,
  viewCamera: viewCameraReducer,
  viewChatDeleteConfirmation: viewChatDeleteConfirmationReducer,
  groupChatMemberSelect: groupChatMemberSelectReducer,
  selectMessageState: selectMessageReducer,
  messageTargetState: messageTargetReducer,
  clientWindowState: clientWindowReducer,
  chatSettingState: chatSettingReducer,
  groupAttachedFileState: groupAttachedFileReducer,
  oneToOneAttachFileState: oneToOneAttachFileReducer,
  shareGroupFile: groupAttachedFileReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logoutStore') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
