import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface viewCameraState {
  viewCameraStatus: boolean;
}

const initialState: viewCameraState = {
    viewCameraStatus: false,
}

export const viewCameraSlice = createSlice({
  name: 'viewCamera',
  initialState,
  reducers: {
    setViewCameraStatus: (state, action: PayloadAction<any>) => {
      state.viewCameraStatus = action.payload
    }
  },
})

export const { setViewCameraStatus } = viewCameraSlice.actions

export default viewCameraSlice.reducer