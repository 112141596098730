import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact, GroupChatMember } from 'models/AppStateModel.type';

export interface CurrentChatGroupContact {
  checked: boolean;
  name: string;
  id: string;
  contact: Contact;
}

export interface ChatMemberSelectState {
  status: CurrentChatGroupContact[];
}

const initialState: ChatMemberSelectState = {
  status: [],
};

export const groupChatMemberSelectSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    selectedMember: (state, action) => {
      state.status = action.payload;
    },
    defineAvailableAllMembers: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { selectedMember, defineAvailableAllMembers } =
  groupChatMemberSelectSlice.actions;

export default groupChatMemberSelectSlice.reducer;
