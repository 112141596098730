import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from 'models/CurrentChatModel.type';
import {
  GroupChatMessage,
  TypingMembers,
} from 'models/CurrentGroupChatModel.type';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface MessageTargetType {
  editOneToOneMessageTarget: GroupChatMessage | ChatMessage | null;
  editMessageTarget: GroupChatMessage | ChatMessage | null;
  replyMessageTarget: GroupChatMessage | ChatMessage | null;
  replyOneToOneMessageTarget: GroupChatMessage | ChatMessage | null;
  menuMessageTarget: GroupChatMessage | ChatMessage | null;
  typingMembers: TypingMembers[];
  typingOneToOneMembers: TypingMembers[];
}

const initialState: MessageTargetType = {
  editOneToOneMessageTarget: null,
  editMessageTarget: null,
  replyMessageTarget: null,
  replyOneToOneMessageTarget: null,
  menuMessageTarget: null,
  typingMembers: [],
  typingOneToOneMembers: [],
};

export const messageTargetSlice = createSlice({
  name: 'selectMessage',
  initialState,
  reducers: {
    setEditMessageTarget: (
      state,
      action: PayloadAction<GroupChatMessage | ChatMessage | null>
    ) => {
      state.editMessageTarget = action.payload;
      state.replyMessageTarget = null;
    },
    setReplyMessageTarget: (
      state,
      action: PayloadAction<GroupChatMessage | ChatMessage | null>
    ) => {
      state.replyMessageTarget = action.payload;
      state.editMessageTarget = null;
    },

    setEditOneToOneMessageTarget: (
      state,
      action: PayloadAction<ChatMessage | null>
    ) => {
      state.editOneToOneMessageTarget = action.payload;
      state.replyOneToOneMessageTarget = null;
    },

    setReplyOneToOneMessageTarget: (
      state,
      action: PayloadAction<ChatMessage | null>
    ) => {
      state.replyOneToOneMessageTarget = action.payload;
      state.editOneToOneMessageTarget = null;
    },
    setMenuMessageTarget: (
      state,
      action: PayloadAction<GroupChatMessage | ChatMessage | null>
    ) => {
      state.menuMessageTarget = action.payload;
    },
    setTypingMember: (state, action: PayloadAction<TypingMembers[] | []>) => {
      state.typingMembers = action.payload;
    },

    updateTypingMember: (state, action: PayloadAction<TypingMembers>) => {
      const payload = action.payload;

      const memberIndex = state.typingMembers.findIndex(
        (member) => member.senderId === payload.senderId
      );
      if (payload.isTyping && memberIndex < 0) {
        state.typingMembers.push(payload);
      } else {
        state.typingMembers.splice(memberIndex, 1);
      }
    },

    removeTypingMember: (state, action: PayloadAction<string>) => {
      const memberIndex = state.typingMembers.findIndex(
        (member) => member.senderId === action.payload
      );

      if (memberIndex >= 0) state.typingMembers.splice(memberIndex, 1);
    },

    setTypingOneToOneMember: (
      state,
      action: PayloadAction<TypingMembers[] | []>
    ) => {
      state.typingOneToOneMembers = action.payload;
    },

    // updateTypingOneToOneMember: (state, action: PayloadAction<TypingMembers>) => {
    // const payload = action.payload;

    //   const memberIndex = state.typingOneToOneMembers.findIndex(
    //     (member) => member.senderId === payload.senderId
    //   );
    //   if (payload.isTyping && memberIndex < 0) {
    //     state.typingOneToOneMembers.push(payload);
    //   } else {
    //     state.typingOneToOneMembers.splice(memberIndex, 1);
    //   }
    // },

    // removeTypingOneToOneMember: (state, action: PayloadAction<string>) => {
    //   const memberIndex = state.typingOneToOneMembers.findIndex(
    //     (member) => member.senderId === action.payload
    //   );

    //   if (memberIndex >= 0) state.typingOneToOneMembers.splice(memberIndex, 1);
    // },
  },
});

export const {
  setEditMessageTarget,
  setEditOneToOneMessageTarget,
  setReplyOneToOneMessageTarget,
  setReplyMessageTarget,
  setMenuMessageTarget,
  setTypingMember,
  updateTypingMember,
  removeTypingMember,
  setTypingOneToOneMember,
  // updateTypingOneToOneMember,
  // removeTypingOneToOneMember
} = messageTargetSlice.actions;

export default messageTargetSlice.reducer;

export const useMessageTargetState = () => {
  const messageTargetState: MessageTargetType = useSelector(
    (state: RootState) => state.messageTargetState
  );

  return messageTargetState;
};
