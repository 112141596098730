import { toast } from 'react-hot-toast';

export const actionFeedbackToast = {
  success: (message: string, duration = 2500) =>
    toast.success(message, {
      position: 'top-center',
      duration: duration,
      style: {
        padding: '8px 14px',
        borderRadius: '0.35rem',
      },
    }),
  error: (message: string, duration = 2500) =>
    toast.error(message, {
      position: 'top-center',
      duration: duration,
      style: {
        padding: '8px 14px',
        borderRadius: '0.35rem',
      },
    }),
};
