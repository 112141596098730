const useApi = () => {
  return {
    invokeApiService: async function (apiService: any) {
      const res = await apiService;
      return res;
    },
  }
}

export default useApi
