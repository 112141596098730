import React, { createContext, PropsWithChildren, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { getSocketObj } from 'utils/socket';

type UtilsContextProps = React.Context<{
  utilsState: {
      socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
  };
  setUtilsState: React.Dispatch<React.SetStateAction<{
      socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
  }>>;
}>

export let UtilsContext: UtilsContextProps;

function UtilsContextProvider({ children }: PropsWithChildren) {
  const [utilsState, setUtilsState] = useState<{  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null}>({
    socket :  getSocketObj()
  });
  UtilsContext = createContext({ utilsState, setUtilsState });

  return (
    <UtilsContext.Provider value={{ utilsState, setUtilsState }}>
      {children}
    </UtilsContext.Provider>
  );
}

export default UtilsContextProvider;
