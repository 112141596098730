import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppStatus } from 'models/AppStateModel.type';

export interface SettingState {
  status: AppStatus;
}

const initialState: SettingState = {
  status: {
    isEnableNotificationForAppSetting: true,
    isEnableOnlineStatusForMyAccount: false,
    isWelcomeActive: false,
  },
};

export const settingSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setWelcomeActiveStatus: (state, action: PayloadAction<boolean>) => {
      state.status.isWelcomeActive = action.payload;
    },
    setNotificationStatusForAppSetting: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.status.isEnableNotificationForAppSetting = action.payload;
    },
    setOnlineStatusForMyAccount: (state, action: PayloadAction<boolean>) => {
      state.status.isEnableOnlineStatusForMyAccount = action.payload;
    },
  },
});

export const {
  setWelcomeActiveStatus,
  setNotificationStatusForAppSetting,
  setOnlineStatusForMyAccount,
} = settingSlice.actions;

export default settingSlice.reducer;
