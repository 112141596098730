import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaPayload } from './groupAttachedFileReducer';

export interface viewMediaState {
  viewStatus: boolean;
  groupViewMediaMessage: MediaPayload | null;
  oneToOneViewMeidaMessage: MediaPayload | null;
  mediaList: any;
  currentMediaIndex: number;
}

const initialState: viewMediaState = {
  viewStatus: false,
  groupViewMediaMessage: null,
  oneToOneViewMeidaMessage: null,
  mediaList: [],
  currentMediaIndex: 0,
};

export const viewMediaSlice = createSlice({
  name: 'viewMedia',
  initialState,
  reducers: {
    setViewStatus: (state, action: PayloadAction<any>) => {
      state.viewStatus = action.payload;
    },
    setMediaList: (state, action: PayloadAction<any>) => {
      state.mediaList = action.payload;
    },
    setCurrentMediaIndex: (state, action: PayloadAction<any>) => {
      state.currentMediaIndex = action.payload;
    },

    setGroupViewMediaState: (
      state,
      action: PayloadAction<MediaPayload | null>
    ) => {
      state.groupViewMediaMessage = action.payload;
    },

    setOneToOneViewMediaState: (
      state,
      action: PayloadAction<MediaPayload | null>
    ) => {
      state.oneToOneViewMeidaMessage = action.payload;
    },
  },
});

export const {
  setViewStatus,
  setMediaList,
  setCurrentMediaIndex,
  setGroupViewMediaState,
  setOneToOneViewMediaState
} = viewMediaSlice.actions;

export default viewMediaSlice.reducer;
