import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setCallTimer } from 'store/dialingReducer';

function useSetupCallTimer() {
  const dispatch = useDispatch();
  const startCallTimer = useSelector(
    (state: RootState) => state.dialing.startCallTimer
  );

  const timerRef = useRef<any>(null);

  useEffect(() => {
    const handleStartTimer = () => {
      dispatch(setCallTimer({ mode: 'increment' }));
    };

    if (startCallTimer) {
      timerRef.current = setInterval(handleStartTimer, 1000);
    } else {
      if (timerRef.current !== null) clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current !== null) clearInterval(timerRef.current);
    };
  }, [startCallTimer, timerRef]);

  return {};
}

export default useSetupCallTimer;
