import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function useDialingState() {
  const dialing = useSelector((state: RootState) => state.dialing);

  return dialing;
}

export default useDialingState;
