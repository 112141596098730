import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RoomUser } from 'models/AppStateModel.type';

export interface viewChatDeleteConfirmationState {
    viewChatDeleteConfirmationStatus: boolean;
    deleteChatTarget : RoomUser | null
}

const initialState: viewChatDeleteConfirmationState = {
    viewChatDeleteConfirmationStatus: false,
    deleteChatTarget: null
}

export const setViewChatDeleteConfirmationSlice = createSlice({
  name: 'viewChatDeleteConfirmation',
  initialState,
  reducers: {
    setViewChatDeleteConfirmation: (state, action: PayloadAction<any>) => {
      state.viewChatDeleteConfirmationStatus = action.payload
    },

    setDeleteChatTarget :  (state, action: PayloadAction<RoomUser | null>) => {
      state.deleteChatTarget = action.payload
    }
  },
})

export const { setViewChatDeleteConfirmation, setDeleteChatTarget } = setViewChatDeleteConfirmationSlice.actions

export default setViewChatDeleteConfirmationSlice.reducer