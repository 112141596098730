import axios from 'axios';
import { getCredentials } from './auth';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
});

export const teatalkApi = axios.create({
  baseURL: `${process.env.REACT_APP_TEATALK_API}/api/v1`,
});

export default api;
