import React from 'react';
import { ToastBar, Toaster } from 'react-hot-toast';

function NotiToaster() {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 3000,
        style: {
          border: '1px solid #F7941D',
          padding: '12px 16px',
          color: '#F7941D',
        },
        success: {
          iconTheme: {
            // primary: '#4ade80', // green
            primary: '#F7941D', // primary color
            secondary: '#FFFAEE',
          },
        },
        error: {
          iconTheme: {
            primary: '#ef4444',
            secondary: '#FFFAEE',
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon} {message}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default NotiToaster;
