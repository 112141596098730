import { MESSAGE_FLAG } from 'utils/constants';
import { ChatMessage } from './CurrentChatModel.type';

export interface CurrentGroupChatContact {
  createdAt: string | Date;
  created_by: string;
  members: CurrentGroupChatMembers[];
  activeMember: string[];
  name: string;
  groupImageUrl: string;
  is_admin: boolean;
  _id: string;
}

export interface CurrentGroupChatMembers {
  contact_id: string;
  friendId: number[];
  group_id: string;
  image: string[];
  is_active: boolean[];
  is_admin: 1 | 0;
  name: string[];
  unread: number;
  user_id: string[];
  _id: string;
}

export interface LastMessage {
  createdAt: string;
  file_upload: string;
  flag: MESSAGE_FLAG;
  group_id?: string;
  message?: string;
  sender_id: string;
  sender_name: string;
  unread: number | string;
  updatedAt: string;
  lastmsg_id?: string | number;
  __v: number;
  _id: string;
}

export interface LastOneToOneMessage {
  createdAt: string;
  file_upload: string;
  flag: MESSAGE_FLAG;
  message: string;
  name?: string;
  sender_id: string;
  unread: number | string;
  updatedAt: string;
  lastmsg_id?: string | number;
  __v: number;
  _id: string;
}

export interface GroupOnlineUsers {
  count: number;
  users: [];
}

export interface GroupChatMessage {
  createdAt: string;
  deleteforme: any[];
  duration: number;
  file_upload?: string;
  file_size: number;
  fileURL?: string;
  flag: MESSAGE_FLAG;
  group_id: string;
  image: string[];
  message: string;
  missed: number;
  name: string[];
  read_users: SeenMembers[];
  reply_id?: string;
  reply_name: string[];
  reply_file?: string[];
  replyToMessage: GroupChatMessage[];
  reply_message?: string[] | undefined;
  sender_id: string;
  updatedAt?: string;
  sending?: boolean;
  _id: string;
  tempMsgId?: string;
}

export interface SeenMembers {
  name: string;
  profileImage: string;
  readAt: number | string;
  userId: string;
}

export interface TypingMembers {
  groupId?: string;
  isTyping: boolean;
  senderId: string;
  senderImage: string;
  senderName: string;
}

export const groupMessageDefault: GroupChatMessage = {
  createdAt: new Date().toDateString(),
  deleteforme: [],
  duration: 0,
  file_upload: '',
  file_size: 0,
  flag: MESSAGE_FLAG.NORMAL,
  group_id: '',
  image: [],
  message: '',
  missed: 0,
  name: [],
  read_users: [],
  reply_id: '',
  reply_name: [],
  reply_file: [],
  replyToMessage: [],
  reply_message: [],
  sender_id: '',
  _id: '',
  sending: false,
};
