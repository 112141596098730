import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChatSetting {
  chatSettingIsOpen: boolean;
  groupSettingIsOpen: boolean;
  groupSettingTransiDisabled: boolean;
  oneToOneSettingTransiDisabled:boolean;
}

const initialState: ChatSetting = {
  chatSettingIsOpen: false,
  groupSettingIsOpen: false,
  groupSettingTransiDisabled: false,
  oneToOneSettingTransiDisabled:false
};

export const chatSettingSlice = createSlice({
  name: 'chatSetting',
  initialState,
  reducers: {
    setChatSettingOpenState: (state, action: PayloadAction<boolean>) => {
      state.chatSettingIsOpen = action.payload;
    },
    toggleChatSettingOpenState: (state, action: PayloadAction<null>) => {
      state.chatSettingIsOpen = !state.chatSettingIsOpen;
    },
    setGroupSettingOpenState: (state, action: PayloadAction<boolean>) => {
      state.groupSettingIsOpen = action.payload;
    },
    toggleGroupSettingOpenState: (state, action: PayloadAction<null>) => {
      state.groupSettingIsOpen = !state.groupSettingIsOpen;
    },
    setGroupSettingTransiDisabled: (state, action: PayloadAction<boolean>) => {
      state.groupSettingTransiDisabled = action.payload;
    },

    setOneToOneSettingTransiDisabled: (state, action: PayloadAction<boolean>) => {
      state.oneToOneSettingTransiDisabled = action.payload;
    },
  },
});

export const {
  setChatSettingOpenState,
  toggleChatSettingOpenState,
  setGroupSettingOpenState,
  toggleGroupSettingOpenState,
  setGroupSettingTransiDisabled,
  setOneToOneSettingTransiDisabled
} = chatSettingSlice.actions;

export default chatSettingSlice.reducer;
