import { useCallback } from 'react';

import { useUserData } from './useProfile';
import { useWebSocket } from './useWebSocket';
import { MESSAGE_FLAG } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateGroupList } from 'store/appReducer';
import {
  useGetGroupMembers,
  useGetGroupMessages,
} from './useGroupSocketListener';
import { RootState } from 'store';
import { setCurrentGroupContact } from 'store/currentGroupChatReducer';
import {
  setGroupSettingOpenState,
  toggleGroupSettingOpenState,
} from 'store/chatSettingReducer';

export function useGroupCreate() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const createGroup = (newGroupData: {
    name: string;
    description: string;
    contact_list: string[];
    user_id?: string;
    coverImgName: string;
    coverImgSize: number;
    add_groupmember: boolean;
    chat_history: boolean;
    edit_group: boolean;
  }) => {
    // console.log(newGroupData);
    socket.emit('createGroups', {
      ...newGroupData,
      userId,
    });
  };

  return createGroup;
}

export function useClickGroupChat() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const getGroupMessages = useGetGroupMessages();
  const getGroupMembers = useGetGroupMembers();

  const clickGroupChat = useCallback(
    ({ groupId, startm = 0 }: { groupId: string; startm?: number }) => {
      console.log('groupClick ', {
        groupId,
        startm,
      });
      getGroupMessages();

      if (startm < 10) {
        getGroupMembers();
      }

      socket.emit('groupClick', { groupId, userId, startm });
    },
    [userId, socket, getGroupMessages, getGroupMembers]
  );

  return clickGroupChat;
}

export function useSendGroupMessage() {
  const { userId } = useUserData();
  const socket = useWebSocket();
  const dispatch = useDispatch();

  const sendGroupMessage = useCallback(
    ({
      message = '',
      sender_id = userId,
      group_id,
      fileName,
      flag = MESSAGE_FLAG.NORMAL,
      replyId = '',
      fileSize = 0,
      replySenderId = '',
      reply_name = '',
      user_id = userId,
      missed,
      duration,
      tempMsgId,
    }: {
      message?: string;
      sender_id?: string;
      group_id: string;
      fileName?: string;
      fileSize?: number;
      flag?: MESSAGE_FLAG;
      replyId?: string;
      replySenderId?: string;
      reply_name?: string;
      user_id?: string;
      missed?: number;
      duration?: number;
      tempMsgId?: string;
    }) => {
      socket.off('groupMessage');
      socket.off('gchat-pg');

      socket.emit('group message', {
        message,
        sender_id,
        group_id,
        file_upload: fileName,
        flag,
        reply_id: replyId,
        file_size: fileSize,
        missed,
        duration,
        reply_name,
        reply_sender_id: replySenderId,
        userId: user_id,
        tempMsgId: tempMsgId,
      });
    },
    [userId, socket]
  );

  const editGroupMessage = ({
    messageId,
    message,
    flag = MESSAGE_FLAG.EDIT,
    groupId,
  }: {
    messageId: string;
    message: string;
    flag?: MESSAGE_FLAG;
    groupId: string;
  }) => {
    socket.emit('groupMessage_update', { messageId, message, flag, groupId });
  };

  return { sendGroupMessage, editGroupMessage };
}

export function useGroupMemberTyping() {
  const socket = useWebSocket();
  const { userId, username, profileImageUrl } = useUserData();

  const emitGroupMemberTyping = useCallback(
    (payload: { isTyping: boolean; groupId: string }) => {
      socket.emit('groupTyping', {
        ...payload,
        senderId: userId,
        senderName: username,
        senderImage: profileImageUrl,
      });
    },
    [socket, userId, username, profileImageUrl]
  );

  return emitGroupMemberTyping;
}

export function useGroupMenuHandler() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();

  const currentGroupContact = useSelector(
    (state: RootState) => state.currentGroupChat.groupContact
  );

  const readGroupMessageUpdate = useCallback(
    ({ groupId, unread }: { groupId: string; unread: number }) => {
      socket.emit('unreadGroupMsgUpdate', {
        groupId,
        unread,
        userId,
      });
    },
    [userId, socket]
  );

  const muteGroup = useCallback(
    ({ groupId, is_muted }: { groupId: string; is_muted: number }) => {
      // console.log({ groupId, userId, is_muted });
      socket.emit('updateGroupNotification', {
        groupId,
        userId,
        is_muted,
      });
    },
    [userId, socket]
  );

  const deleteGroup = useCallback(
    ({ groupId }: { groupId: string }) => {
      // console.log("allGroupMessageDelete")
      socket.emit('allGroupMessageDelete', {
        groupId,
        userId,
      });

      dispatch(updateGroupList({ groupId }));
      if (groupId === currentGroupContact?._id) {
        dispatch(setGroupSettingOpenState(false));
        dispatch(setCurrentGroupContact(null));
        dispatch(
          updateGroupList({
            groupId,
          })
        );
      }
    },
    [userId, socket, currentGroupContact]
  );

  return {
    readGroupMessageUpdate,
    muteGroup,
    deleteGroup,
  };
}

export function useDeleteAllGroupMessage() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const deleteAllGroupMessage = useCallback(
    ({ groupId }: { groupId: string }) => {
      socket.emit('all_Group_Message_delete', {
        groupid: groupId,
        userId,
      });
    },
    [userId, socket]
  );

  return deleteAllGroupMessage;
}

// Deprecated socket / not working
export function useSearchMemberList() {
  const socket = useWebSocket();
  const searchMemberList = ({
    groupId,
    searchText,
  }: {
    groupId: string;
    searchText: string;
  }) => {
    console.log(searchText);
    socket.emit('groupMemberList', {
      groupId,
      searchText,
    });
  };
  return searchMemberList;
}

export function useLeaveFromGroup() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();
  const currentGroupId = useSelector(
    (state: RootState) => state.currentGroupChat.groupContact?._id
  );

  const leaveFromGroup = useCallback(
    ({ groupId }: { groupId: string }) => {
      socket.emit('leaveFromGroup', {
        userId,
        groupId,
      });
      dispatch(updateGroupList({ groupId }));
      if (currentGroupId === groupId) {
        dispatch(setGroupSettingOpenState(false));
        dispatch(setCurrentGroupContact(null));
      }
    },
    [userId, socket, currentGroupId]
  );
  return leaveFromGroup;
}

export function usePromoteAsAdmin() {
  const socket = useWebSocket();
  const promoteAsAdmin = ({
    memberId,
    groupId,
  }: {
    memberId: string;
    groupId: string;
  }) => {
    console.log({ memberId, groupId });
    socket.emit('promoteAsAdmin', { userId: memberId, groupId });
  };

  return promoteAsAdmin;
}

export const useUserReadGroupMsg = () => {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const addReadUserToGroupMsg = useCallback(
    ({ messageId }: { messageId: string }) => {
      // console.log('addReadUserToGroupMsg')
      socket.emit('addReadUserToGroupMsg', {
        messageId,
        userId,
      });
    },
    [socket, userId]
  );

  const removeReadUserFromGroupMsg = useCallback(
    ({ messageId }: { messageId: string }) => {
      socket.emit('removeReadUserFromGroupMsg', {
        messageId,
        userId,
      });
    },
    [socket, userId]
  );

  return { addReadUserToGroupMsg, removeReadUserFromGroupMsg };
};

export const useDeleteGroupMessages = () => {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const deleteGroupMessages = useCallback(
    ({
      msgIdArr = [],
      groupId,
      alsoBuddy,
    }: {
      msgIdArr: string[];
      groupId: string;
      alsoBuddy: number;
    }) => {
      socket.emit('single_Group_Message_delete', {
        msgIdArr,
        groupId,
        alsoBuddy,
        userId,
      });
    },
    [userId, socket]
  );

  return deleteGroupMessages;
};

export const useAddNewMember = () => {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const addNewMember = ({
    contact_list,
    groupId,
  }: {
    contact_list: string[];
    groupId: string;
  }) => {
    socket.emit('addGroupContacts', { contact_list, groupId, userId });
  };

  return addNewMember;
};
// export function useGroupCreate() {
//   const socket = useWebSocket();
//   const { userId } = useUserData();

//   const createGroup = (newGroupData: {
//     name: string;
//     description: string;
//     contact_list: string[];
//     user_id?: string;
//     coverImgName: string;
//     coverImgSize: number;
//     add_groupmember: boolean;
//     chat_history: boolean;
//     edit_group: boolean;
//   }) => {
//     // console.log(newGroupData);
//     socket.emit('createGroups', {
//       ...newGroupData,
//       userId,
//     });
//   };
export function useGetGroupInfoSocket() {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const getGroupInfo = useCallback(
    ({ groupId }: { groupId: string }) => {
      socket.emit('contactsDetail', { groupId, userId });
    },
    [userId, socket]
  );

  return { getGroupInfo };
}

export function useUpdateGroupInfo() {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const updateGroupInfo = useCallback(
    (info: {
      groupId: string;
      name: string;
      description: string;
      coverImgName: string;
      coverImgSize: number;
      add_groupmember: boolean;
      chat_history: boolean;
      edit_group: boolean;
      is_muted: 1 | 0;
    }) => {
      socket.emit('updateGroupName', { ...info, userId });
    },
    [socket, userId]
  );
  return { updateGroupInfo };
}

export function useGroupMemberHandler() {
  const socket = useWebSocket();

  const promoteAsAdmin = useCallback(
    ({ memberId, groupId }: { memberId: string; groupId: string }) => {
      socket.emit('promoteAsAdmin', { userId: memberId, groupId });
    },
    [socket]
  );
  const demoteAsMember = useCallback(
    ({ memberId, groupId }: { memberId: string; groupId: string }) => {
      socket.emit('demoteAsMember', { userId: memberId, groupId });
    },
    [socket]
  );
  const banFromGroup = useCallback(
    ({ memberId, groupId }: { memberId: string; groupId: string }) => {
      socket.emit('banFromGroup', { userId: memberId, groupId });
    },
    [socket]
  );

  return {
    promoteAsAdmin,
    demoteAsMember,
    banFromGroup,
  };
}
