import { useDispatch } from 'react-redux';
import { useWebSocket } from './useWebSocket';
import { useUserData } from './useProfile';
import { CALL_TYPE } from 'utils/constants';
import { JitsiPayload } from 'models/DialingStateModel.type';
import {
  addGroupCallParticipant,
  setCurrentGroupCall,
  setCurrentlyOnCall,
} from 'store/dialingReducer';

interface GroupCallActionPayload {
  callFromUserId: string;
  groupId: string;
  callType: CALL_TYPE;
}

export function useGroupCallSocketEmitter() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();

  const groupCallAccept = (
    payload: GroupCallActionPayload & {
      jitsi: JitsiPayload;
    }
  ) => {
    socket.emit('gpCallAccept', {
      ...payload,
      callAcceptedBy: userId,
    });
  };

  const groupCallReject = (payload: GroupCallActionPayload) => {
    socket.emit('gpCallReject', {
      ...payload,
      callRejectedBy: userId,
    });
  };

  const groupCallCancel = (payload: GroupCallActionPayload) => {
    socket.emit('gpCallCancel', {
      ...payload,
      callCanceledBy: userId,
    });
  };

  return { groupCallAccept, groupCallReject, groupCallCancel };
}

export function useGroupCallRing() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();

  const groupCallRing = (payload: {
    callToGroupId: string;
    callToGroupName: string;
    groupImage: string;
    callType: CALL_TYPE;
  }) => {
    console.log(userId);
    socket.emit('gpCallRing', {
      callToGroupId: payload.callToGroupId,
      callToGroupName: payload.callToGroupName,
      groupImage: payload.groupImage,
      callFromUserId: userId,
    });

    dispatch(
      setCurrentGroupCall({
        groupId: payload.callToGroupId,
        groupName: payload.callToGroupName,
        groupImage: payload.groupImage,
        callFromUserId: userId,
        callType: payload.callType,
      })
    );

    dispatch(addGroupCallParticipant({ id: userId }));
    dispatch(setCurrentlyOnCall(true));
  };

  return groupCallRing;
}

export function useGroupCallEnd() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const groupCallEnd = (payload: GroupCallActionPayload) => {
    socket.emit('gpCallEnd', {
      ...payload,
      callEndedBy: userId,
    });
  };

  return groupCallEnd;
}
