import { InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    placeholder: string;
    label: string;
    name: string;
    register: any;
    errors: any;
    validationSchema: {
        required: boolean | string;
    };
}

function Input({placeholder, label, name, register, errors, validationSchema}:Props) {
    return ( 
        <>
        <div>
            <label htmlFor={name} className="block mb-1 text-text text-xl">{label}</label>
            <input 
            type="text" 
            placeholder={placeholder} 
            id={name} 
            {...register(name, validationSchema)}
            className="w-full py-2 px-2 outline-none rounded-sm transition ease-in-out border-[1px] border-[gray] focus:border-primary"  />
        </div>

        {errors[name] && <div className="text-xs text-[red]">{errors[name].message}</div>}
        </>
    );
}

export default Input;