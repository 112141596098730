import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MediaPayload {
  createdAt: string;
  file_size: number;
  file_upload: string;
  group_id?: string;
  receiver_id?: string;
  message: string;
  sender_id: string;
  updatedAt: string | Date;
  _id: string;
}

interface OneToOneAttachedFile {
  oneToOneMedia: MediaPayload[];
  allOneToOneMediaLoaded: boolean;
  oneToOneFiles: MediaPayload[];
  allOneToOneFileLoaded: boolean;
  oneToOneLinks: MediaPayload[];
  allOneToOneLinkLoaded: boolean;
}

const initialState: OneToOneAttachedFile = {
  oneToOneMedia: [],
  allOneToOneMediaLoaded: false,
  oneToOneFiles: [],
  allOneToOneFileLoaded: false,
  oneToOneLinks: [],
  allOneToOneLinkLoaded: false,
};

export const oneToOneAttachedFileSlice = createSlice({
  name: 'oneToOneAttachedFile',
  initialState,
  reducers: {
    setOneToOneMedia: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneMedia = action.payload;
    },
    addOneToOneMedia: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneMedia.push(...action.payload);
    },
    removeOneToOneMedia: (
      state,
      { payload }: PayloadAction<{ _id: string }[]>
    ) => {
      payload.forEach((payloadMedia) => {
        const mediaIndex = state.oneToOneMedia.findIndex(
          (oneMedia) => oneMedia._id === payloadMedia._id
        );
        if (mediaIndex >= 0) state.oneToOneMedia.splice(mediaIndex, 1);
      });
    },
    setOneToOneMediaLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allOneToOneMediaLoaded = action.payload;
    },
    setOneToOneFiles: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneFiles = action.payload;
    },
    addOneToOneFiles: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneFiles.push(...action.payload);
    },
    setOneToOneFileLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allOneToOneFileLoaded = action.payload;
    },
    setOneToOneLinks: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneLinks = action.payload;
    },
    addOneToOneLinks: (state, action: PayloadAction<MediaPayload[]>) => {
      state.oneToOneLinks.push(...action.payload);
    },
    setOneToOneLinkLoadedState: (state, action: PayloadAction<boolean>) => {
      state.allOneToOneLinkLoaded = action.payload;
    },
  },
});

export const {
  setOneToOneMedia,
  addOneToOneMedia,
  removeOneToOneMedia,
  setOneToOneMediaLoadedState,
  setOneToOneFiles,
  addOneToOneFiles,
  setOneToOneFileLoadedState,
  setOneToOneLinks,
  addOneToOneLinks,
  setOneToOneLinkLoadedState,
} = oneToOneAttachedFileSlice.actions;
export default oneToOneAttachedFileSlice.reducer;
