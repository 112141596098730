import './App.css';
import ReduxProvider from 'providers/ReduxProvider';
import AppRoutes from 'routes/AppRoutes';
import UtilsContextProvider from 'contexts/UtilsContextProvider';

function App() {
  return (
    <ReduxProvider>
      <UtilsContextProvider>
        <AppRoutes />
      </UtilsContextProvider>
    </ReduxProvider>
  );
}

export default App;
