import { useEffect, useState, useCallback } from 'react';
import { useWebSocket } from './useWebSocket';
import useAppState from './useAppState';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushNewGroup,
  setSocketNeedToSync,
  updateGroupInRoomGroups,
  updateGroupList,
  updateLastActivityGroupChat,
  updateTypingInRoomGroups,
} from 'store/appReducer';
import {
  addPaginatedMessage,
  editGroupMessage,
  populateMessages,
  pushGroupMessage,
  setCurrentGroupContact,
  setCurrentGroupMembers,
  setGroupOnlineUsers,
  setGroupTotalMessageCount,
  setLoadingGroupData,
  setLoadingGroupOldMsgStatus,
  updateCurrentGroupContact,
  updateGroupDeletedMessage,
  updateGroupMessage,
  updateMessageReadList,
} from 'store/currentGroupChatReducer';
import { useUserData } from './useProfile';
import useCurrentGroupChat from './useCurrentGroupChat';
import {
  useGroupMenuHandler,
  useUserReadGroupMsg,
} from './useGroupSocketEmitter';
import { CALL_TYPE, MESSAGE_FLAG } from 'utils/constants';
import { CurrentGroupChatMembers } from 'models/CurrentGroupChatModel.type';
import {
  setGroupSettingOpenState,
  toggleGroupSettingOpenState,
} from 'store/chatSettingReducer';
import { actionFeedbackToast } from 'libs/reactHotToastConfg';
import { RootState } from 'store';
import {
  removeTypingMember,
  updateTypingMember,
} from 'store/messageTargetReducer';
import {
  addGroupCallParticipant,
  setCurrentGroupCall,
  setCurrentlyOnCall,
} from 'store/dialingReducer';
import { useCallBusy } from './useCallSocketEmitter';
import useDialingState from './useDialingState';
import { emitNotification } from 'utils';

function useGroupSocketListener() {
  const socket = useWebSocket();
  const dispatch = useDispatch();
  const { userId, userToken } = useUserData();
  const { appState } = useAppState();
  const { currentlyOnCall } = useDialingState();
  const currentGroupChat = useCurrentGroupChat();
  const accountNotifiactionEnabled = useSelector(
    (state: RootState) => state.setting.status.isEnableNotificationForAppSetting
  );
  const { addReadUserToGroupMsg, removeReadUserFromGroupMsg } =
    useUserReadGroupMsg();
  const { readGroupMessageUpdate } = useGroupMenuHandler();
  const callBusy = useCallBusy();

  useEffect(() => {
    socket.on('group-add', (data) => {
      console.log('group-add :: ', data);
      const isAdmin = data.userId === userId ? 1 : 0;
      dispatch(
        pushNewGroup({
          _id: data.grp_id,
          description: data.description,
          groupImage: data.coverImgName,
          isAdmin,
          is_muted: 0,
          name: data.name,
          unreadMessageCount: 0,
          lastMessage: null,
          createdAt: new Date().toLocaleString(),
          lastTypingMember: null,
        })
      );
    });

    // socket.on('group-added', (data) => {
    //   console.log('group-added :: ', data);
    //   const addedGroup = data.group[0];
    //   dispatch(
    //     pushNewGroup({
    //       _id: addedGroup._id,
    //       description: addedGroup.description,
    //       groupImage: addedGroup.groupImage,
    //       isAdmin: addedGroup.isAdmin,
    //       is_muted: addedGroup.is_muted,
    //       name: addedGroup.name,
    //       unreadMessageCount: addedGroup.unreadMessageCount,
    //       lastMessage: addedGroup.lastMessage,
    //       createdAt: addedGroup.createdAt,
    //       lastTypingMember: null,
    //     })
    //   );
    // });

    socket.on('client_unreadGroupMsgUpdate', (data) => {
      console.log('client_unreadGroupMsgUpdate:: ', data);
      dispatch(
        updateGroupInRoomGroups({
          _id: data.groupId,
          unreadMessageCount: data.unread,
        })
      );
    });

    socket.on('client_updateGroupNotification', (data) => {
      // console.log('client_updateGroupNotification',data)
      dispatch(
        updateGroupInRoomGroups({
          _id: data.groupId,
          is_muted: data.is_muted,
        })
      );
    });

    socket.on('client_promote_as_admin', (data) => {
      // console.log('client_promote_as_admin :: ', data);
      const currentUser = data.groupUsers.find(
        (user: any) => user.user_id[0] === userId
      );
      dispatch(
        updateGroupInRoomGroups({
          _id: data.groupId,
          isAdmin: currentUser.is_admin,
        })
      );
    });

    socket.on('onlineGroupUsers', (data) => {
      console.log('onlineGroupUsers:: ', data);
      dispatch(setGroupOnlineUsers(data.onlineUsers));
    });

    socket.on('newReadUserInGroupMessage', (data) => {
      console.log('newReadUserInGroupMessage ', data);
      dispatch(updateMessageReadList(data));
    });

    socket.on('group_last_message', (data) => {
      console.log('group_last_message', data);
    });

    socket.on('currentGroupChattingUser', ({ memberInfo }) => {
      console.log('currentGroupChattingUser : ', memberInfo);
    });

    // socket.on('groupMemberList', (data) => {
    //   console.log('groupMemberList :: ', data);
    // });

    socket.on('searchGroupList', (data) => {
      console.log('searchGroupList :: ', data);
    });

    return () => {
      socket.off('group-add');
      socket.off('group-added');
      socket.off('client_unreadGroupMsgUpdate');
      socket.off('client_updateGroupNotification');
      // socket.off('groupMemberList');
      socket.off('searchGroupList');
      socket.off('client_promote_as_admin');
      socket.off('onlineGroupUsers');
      socket.off('currentGroupChattingUser');
      socket.off('newReadUserInGroupMessage');
      socket.off('group_last_message');
    };
  }, [socket, userToken, appState.socketNeedToSync]);

  // currentGroupChat / roomGroups
  useEffect(() => {
    socket.on('group message', (data) => {
      console.log('group message (receive) :: ', data);
      const receiverGroup = appState.list.roomGroups?.find(
        (group) => group._id === data.message.group_id
      );
      console.log('recive group', receiverGroup);

      if (receiverGroup) {
        const unreadCount: number =
          currentGroupChat.groupContact?._id === data.message.group_id
            ? 0
            : receiverGroup?.unreadMessageCount + 1;
        dispatch(
          updateGroupInRoomGroups({
            _id: data.message.group_id,
            lastMessage: {
              ...receiverGroup.lastMessage,
              ...data.message,
              message: data.message.message,
              createdAt: new Date().toLocaleString(),
              sender_name: data.senderName,
              file_upload: data.message.file_upload || '',
            },
            unreadMessageCount: unreadCount,
          })
        );

        dispatch(
          updateLastActivityGroupChat({ groupId: data.message.group_id })
        );
      }

      if (data.message.group_id === currentGroupChat.groupContact?._id) {
        addReadUserToGroupMsg({ messageId: data.message._id });
        readGroupMessageUpdate({
          groupId: data.message.group_id,
          unread: 0,
        });

        dispatch(removeTypingMember(data.message.sender_id));

        console.log(data.message.sender_id, userId);
        if (data.message.sender_id == userId) {
          dispatch(
            updateGroupMessage({
              ...data.message,
              createdAt: new Date().toLocaleString(),
              image: [data.senderImage],
              name: [data.senderName],
              replyToMessage: data.replyToMessage ? [data.replyToMessage] : [],
              reply_name: [data.reply_name],
              sending: false,
              tempMsgId: data.tempMsgId,
            })
          );
        } else {
          dispatch(
            pushGroupMessage({
              ...data.message,
              createdAt: new Date().toLocaleString(),
              image: [data.senderImage],
              name: [data.senderName],
              replyToMessage: data.replyToMessage ? [data.replyToMessage] : [],
              reply_name: [data.reply_name],
            })
          );
        }

        dispatch(
          setGroupTotalMessageCount(currentGroupChat.totalMessageCount + 1)
        );
      }

      // Emit desktop notification
      if (
        data.message.sender_id != userId &&
        accountNotifiactionEnabled &&
        receiverGroup?.is_muted != 1 &&
        data.message.flag != MESSAGE_FLAG.AUDIO &&
        data.message.flag != MESSAGE_FLAG.VIDEO
      ) {
        console.log('Notificatiion condition passed');
        emitNotification({
          is_muted: false,
          sender_name: receiverGroup?.name,
          sender_image: `${process.env.REACT_APP_MEDIA_FILE_BASE_URL}/${receiverGroup?.groupImage}`,
          file_upload: `${process.env.REACT_APP_MEDIA_FILE_BASE_URL}/${data.file_upload}`,
          message: `${data.senderName}: ${
            data.message.message || data.message.file_upload
          }`,
        });
      }
    });

    socket.on('groupTyping', (data) => {
      console.log('groupTyping:: ', data);

      if (data.senderId === userId) return;
      if (data.groupId === currentGroupChat.groupContact?._id) {
        dispatch(updateTypingMember(data));
      }
      dispatch(updateTypingInRoomGroups(data));
    });

    socket.on('client_single_group_message_delete', (data) => {
      console.log('client_single_group_message_delete', data);

      if (data.groupId === currentGroupChat.groupContact?._id) {
        dispatch(
          updateGroupDeletedMessage({
            ...data,
            deleteforme: data.deleteforme || [],
          })
        );
      }

      const receiverGroup = appState.list.roomGroups?.find(
        (group) => group._id === data.groupId
      );

      if (!receiverGroup) return;
      const lastMessageIsDeleted = data.msgIdArr.includes(
        receiverGroup.lastMessage?._id
      );

      if (lastMessageIsDeleted) {
        if (
          data.userId == userId ||
          (data.userId != userId && data.alsoBuddy == 1)
        )
          console.log('all Condition passed');
        dispatch(
          updateGroupInRoomGroups({
            _id: data.groupId,
            lastMessage: {
              ...receiverGroup.lastMessage!,
              flag: MESSAGE_FLAG.DELETE,
            },
          })
        );
      }
    });

    socket.on('client_ban_from_group', (data) => {
      console.log('client_ban_from_group:: ', data);
      if (currentGroupChat.groupContact?._id === data.groupId) {
        dispatch(setCurrentGroupMembers(data?.groupUsers));

        const userGotBanned = data.userId && data.userId === userId;

        if (userGotBanned) {
          dispatch(setGroupSettingOpenState(false));
          dispatch(setCurrentGroupContact(null));
          dispatch(
            updateGroupList({
              groupId: data.groupId,
            })
          );
          actionFeedbackToast.error('You have been removed from the group');
        }
      }
    });

    socket.on('client_leave_from_group', (data) => {
      console.log('client_leave_from_group:: ', data);
      if (currentGroupChat.groupContact?._id === data.groupId) {
        dispatch(setCurrentGroupMembers(data?.groupUsers));
      }
    });

    const updateGroupStateOnGroupManage = (data: any) => {
      if (!data) return;
      const userIdAdmin = data?.groupUsers?.find(
        (user: any) => user?.contact_id === userId
      )?.is_admin;
      if (currentGroupChat.groupContact?._id === data.groupId) {
        dispatch(setCurrentGroupMembers(data?.groupUsers));
        dispatch(
          updateCurrentGroupContact({
            is_admin: userIdAdmin === 1,
          })
        );
      }
      dispatch(
        updateGroupInRoomGroups({
          _id: data.groupId,
          isAdmin: userIdAdmin,
        })
      );
    };

    socket.on('client_promote_as_admin', (data) => {
      console.log('client_promote_as_admin:: ', data);
      updateGroupStateOnGroupManage(data);
    });

    socket.on('client_demote_as_member', (data) => {
      console.log('client_demote_as_member:: ', data);
      updateGroupStateOnGroupManage(data);
    });

    socket.on('gpCallRinging', (data) => {
      console.log('gpCallRinging:: ', data);

      console.log(currentlyOnCall);
      if (currentlyOnCall) {
        return;
      } else {
        dispatch(setCurrentlyOnCall(true));
        dispatch(
          setCurrentGroupCall({
            groupId: data.callToGroupId,
            groupName: data.callToGroupName,
            groupImage: data.groupImage,
            callFromUserId: data.callFromUserId,
            callType: data.callType,
            jitsi: data.jitsi,
          })
        );
      }

      if (data.callFromUserId !== userId) {
        emitNotification({
          is_muted: false,
          sender_name: data?.callToGroupName,
          sender_image: `${process.env.REACT_APP_MEDIA_FILE_BASE_URL}/${data?.groupImage}`,
          message:
            data.callType == CALL_TYPE.AUDIO
              ? 'Incoming audio call'
              : 'Incoming video call',
        });
      }

      dispatch(addGroupCallParticipant({ id: data.callFromUserId }));
    });

    socket.on('groupMessage_update', (data) => {
      console.log('groupMessage_update :: ', data);
      if (data.group.group_id !== currentGroupChat.groupContact?._id) return;
      if (data.group.flag == MESSAGE_FLAG.EDIT) {
        dispatch(
          editGroupMessage({
            id: data.group._id,
            editedMsg: data.group.message,
            flag: data.group.flag,
          })
        );
      }
    });

    // socket.on('groupDetail', (data) => {
    //   console.log('groupDetail :: ', data);
    //   if (data.groupId === currentGroupChat.groupContact?._id) {
    //     dispatch(setCurrentGroupMembers(data.groupUsers));
    //   }
    // });

    return () => {
      socket.off('group message');
      socket.off('groupTyping');
      socket.off('client_single_group_message_delete');
      socket.off('client_ban_from_group');
      socket.off('client_leave_from_group');
      socket.off('client_promote_as_admin');
      socket.off('client_demote_as_member');
      socket.off('gpCallRinging');
      socket.off('groupMessage_update');
      // socket.off('groupDetail');
    };
  }, [
    socket,
    userToken,
    currentGroupChat.groupContact,
    currentGroupChat.totalMessageCount,
    appState.list,
    appState.socketNeedToSync,
    accountNotifiactionEnabled,
  ]);

  // TO BE IMPLEMENTED (LISTENGING)
  useEffect(() => {
    socket.on('groupInfo', (data) => {
      console.log('groupInfo :: ', data);
    });

    socket.on('groupList', (data) => {
      console.log('groupList :: ', data);
    });

    socket.on('groupLists', (data) => {
      console.log('groupLists :: ', data);
    });
    socket.on('groupUsers', (data) => {
      console.log('groupUsers :: ', data);
    });

    // socket.on('forwardLists', (data) => {
    //   console.log('forwardLists :: ', data);
    // });

    socket.on('addMemberList', (data) => {
      console.log('addMemberList :: ', data);
    });

    socket.on('receivedGroupMessage', (data) => {
      console.log('receivedGroupMessage :: ', data);
    });

    socket.on('receivedCallLogs', (data) => {
      // console.log('receivedCallLogs :: ', data);
    });

    socket.on('newRedUserInGroupMessage', (data) => {
      // console.log('newRedUserInGroupMessage :: ', data);
    });

    socket.on('user-list', (data) => {
      // console.log('user-list :: ', data);
    });

    socket.on('chat message', (data) => {
      console.log('chat message :: ', data);
    });

    socket.on('contactLists', (data) => {
      console.log('contactLists :: ', data);
    });
    socket.on('onlineBuddyLists', (data) => {
      // console.log('onlineBuddyLists :: ', data);
    });
    socket.on('userMessage', (data) => {
      console.log('userMessage :: ', data);
    });

    socket.on('show_message', (data) => {
      console.log('show_message :: ', data);
    });
    socket.on('welcome-message', (data) => {
      console.log('welcome-message :: ', data);
    });

    socket.on('unreadMsgUpdate', (data) => {
      console.log('unreadMsgUpdate :: ', data);
    });

    socket.on('isMessage', (data) => {
      console.log('isMessage :: ', data);
    });

    socket.on('client_message_delete', (data) => {
      console.log('client_message_delete :: ', data);
    });

    socket.on('client-userbusy-update', (data) => {
      // console.log('client-userbusy-update :: ', data);
    });
    socket.on('client_contactnoti_update', (data) => {
      // console.log('client_contactnoti_update :: ', data);
    });
    socket.on('invalidToken', (data) => {
      // console.log('invalidToken :: ', data);
    });

    return () => {
      socket.off('groupInfo');
      socket.off('groupLists');
      socket.off('groupList');
      socket.off('groupUsers');
      // socket.off('forwardLists');
      socket.off('show_message');
      socket.off('addMemberList');
      socket.off('receivedGroupMessage');
      socket.off('receivedCallLogs');
      socket.off('newRedUserInGroupMessage');
      socket.off('user-list');
      socket.off('userMessage');
      socket.off('client_message_delete');
    };
  }, [socket]);
  return {};
}

export default useGroupSocketListener;

export function useGetGroupMessages() {
  const socket = useWebSocket();
  const dispatch = useDispatch();
  const currentGroupId = useSelector(
    (state: RootState) => state.currentGroupChat.groupContact?._id
  );

  const getGroupMessages = useCallback(() => {
    socket.off('groupMessage');
    socket.off('gchat-pg');

    socket.on('groupMessage', (data) => {
      console.log('groupMessage :: ', data);

      dispatch(populateMessages(data.groups));
      dispatch(setGroupTotalMessageCount(data.msgno));
      dispatch(setLoadingGroupData(false));
      dispatch(setLoadingGroupOldMsgStatus(false));

      // socket.off('groupMessage');
      // socket.off('gchat-pg');
    });

    socket.on('gchat-pg', (data) => {
      console.log('gchat-pg :: ', data);

      dispatch(addPaginatedMessage(data.groups));
      dispatch(setLoadingGroupData(false));
      dispatch(setLoadingGroupOldMsgStatus(false));

      // socket.off('gchat-pg');
      // socket.off('groupMessage');
    });
  }, [socket, currentGroupId]);

  return getGroupMessages;
}

export function useGetGroupMembers() {
  const socket = useWebSocket();
  const dispatch = useDispatch();

  const getGroupMemberList = useCallback(() => {
    socket.on('groupDetail', (data) => {
      console.log('groupDetail :: ', data);
      dispatch(setCurrentGroupMembers(data.groupUsers));
      socket.off('groupDetail');
    });
  }, [socket]);

  return getGroupMemberList;
}

export function useGetGroupInfo() {
  const socket = useWebSocket();
  const dispatch = useDispatch();

  const getGroupInfo = useCallback(() => {}, [socket]);

  return getGroupInfo;
}
