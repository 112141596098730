import { MESSAGE_FLAG } from 'utils/constants';

export interface CurrentChatContact {
  active: boolean;
  createdAt: string | Date;
  created_by: string;
  friendId: number;
  last_active_date: string;
  name: string;
  profileImageUrl: string;
  receiver_id: string;
  user_busy: string;
  _id: string;
}

export interface ChatMessage {
  _id: string;
  name: string;
  nickName: string[];
  message: string;
  reply_message: string[];
  reply_file: string[];
  reply_id: string | null;
  reply_name: string[];
  flag: MESSAGE_FLAG | string;
  missed: number | string;
  duration?: number;
  sender_id: string;
  receiver_id: string;
  file_upload: string;
  file_size: number;
  fileURL?: string;
  createdAt: string;
  image: string[];
  contact_id: string[];
  msg_id: string;
  replyToMessage?: ChatMessage[];
  unread: '1' | '0';
  updatedAt: string;
  alsoBuddy: '1' | '0';
  sending?: boolean;
  tempMsgId?: string;
}

export const oneToOneMessageDefault: ChatMessage = {
  _id: '',
  name: '',
  nickName: [],
  message: '',
  reply_message: [''],
  reply_file: [''],
  reply_id: null,
  reply_name: [''],
  flag: MESSAGE_FLAG.NORMAL,
  missed: 0,
  duration: 0,
  sender_id: '',
  receiver_id: '',
  file_upload: '',
  file_size: 0,
  createdAt: new Date().toLocaleString(),
  image: [''],
  contact_id: [''],
  msg_id: '',
  replyToMessage: [],
  unread: '0',
  updatedAt: '',
  alsoBuddy: '0',
  sending: false,
};
