import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { Contact } from 'models/AppStateModel.type';
import {
  ChatMessage,
  CurrentChatContact,
  oneToOneMessageDefault,
} from 'models/CurrentChatModel.type';
import { CHAT_TYPE, MESSAGE_FLAG, MESSAGE_PAGI } from 'utils/constants';

export interface currentChatState {
  isNeedToSyncNow: boolean;
  contact: CurrentChatContact | null;
  messages: ChatMessage[];
  messageEnd: boolean;
  lastMessage: ChatMessage | null;
  startM: number;
  loadingOneToOne: boolean;
  totalMessageCount: number;
  currentChatTab: CHAT_TYPE;
  loadingContactData: boolean;
  loadingOldMessage: boolean;
}

const initialState: currentChatState = {
  isNeedToSyncNow: false,
  contact: null,
  messages: [],
  lastMessage: null,
  messageEnd: false,
  startM: 0,
  loadingOneToOne: false,
  totalMessageCount: 0,
  currentChatTab: CHAT_TYPE.SINGLE,
  loadingContactData: false,
  loadingOldMessage: false,
};

export const currentChatSlice = createSlice({
  name: 'currentChat',
  initialState,
  reducers: {
    setIsNeedToSyncNow: (state, action: PayloadAction<boolean>) => {
      state.isNeedToSyncNow = action.payload;
    },

    populateMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      if (action.payload.length < 10) {
        state.messageEnd = true;
      } else {
        state.messageEnd = false;
      }

      if (action.payload.length === 0) {
        state.messages = [];
      } else {
        if (
          state.contact?.receiver_id === action.payload[0].receiver_id ||
          state.contact?.receiver_id === action.payload[0].sender_id
        )
          state.messages = action.payload;
      }
    },

    addPaginatedMessage: (state, action: PayloadAction<ChatMessage[]>) => {
      console.log(action.payload);
      if (action.payload.length < 10) {
        state.messageEnd = true;
      } else {
        state.messageEnd = false;
      }

      if (action.payload.length === 0) return;

      if (
        state.contact?.receiver_id === action.payload[0].receiver_id ||
        state.contact?.receiver_id === action.payload[0].sender_id
      )
        state.messages.push(...action.payload);
    },

    pushMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages.unshift(action.payload);
      state.lastMessage = action.payload;
    },

    updateOnetoOneMsg: (state, action: PayloadAction<Partial<ChatMessage>>) => {
      const payload = action.payload;
      const msgIndex = state.messages.findIndex(
        (message) =>
          message.tempMsgId === (payload.tempMsgId || 0) ||
          message._id === payload._id
      );

      if (msgIndex >= 0) {
        state.messages[msgIndex] = { ...state.messages[msgIndex], ...payload };
      }
    },
    setOneToOneMsgEndStatus: (state, action: PayloadAction<boolean>) => {
      state.messageEnd = action.payload;
    },

    setLoadingGroupData: (state, action: PayloadAction<boolean>) => {
      state.loadingOneToOne = action.payload;
    },

    setLoadingOneToOneMsgStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingOldMessage = action.payload;
    },

    setContinueScrollStartM: (state, action: PayloadAction<boolean>) => {
      if (
        action.payload &&
        state.totalMessageCount >= state.startM &&
        state.messages.length - MESSAGE_PAGI.LIMIT === state.startM
      ) {
        state.startM = state.startM + MESSAGE_PAGI.LIMIT;
      } else if (!action.payload) {
        state.startM = 0;
      }
    },

    setTotalMessageCount: (state, action: PayloadAction<number>) => {
      state.totalMessageCount = action.payload;
    },

    editOneToOneMessage: (
      state,
      action: PayloadAction<{
        id: string;
        editedMsg: string;
        flag: MESSAGE_FLAG;
      }>
    ) => {
      console.log('edit store');
      const payload = action.payload;
      const msgIndex = state.messages.findIndex(
        (message) => message._id === payload.id
      );
      if (msgIndex >= 0) {
        state.messages[msgIndex].message = payload.editedMsg;
        state.messages[msgIndex].flag = payload.flag;
      }
    },

    setCurrentChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = action.payload;
    },

    setCurrentChatContact: (
      state,
      action: PayloadAction<CurrentChatContact | null>
    ) => {
      state.contact = action.payload;
    },

    setCurrentChatTab: (state, action: PayloadAction<CHAT_TYPE>) => {
      state.currentChatTab = action.payload;
    },

    updateOneToOneDeletedMessage: (
      state,
      action: PayloadAction<{
        flag?: MESSAGE_FLAG;
        receiveId: string;
        messageId: string;
        userId?: string;
        alsoBuddy?: '1' | '0';
        // deleteforme: string[];
      }>
    ) => {
      const payload = action.payload;

      // payload.messageId.forEach((msgId) => {
      const messageIndex = state.messages.findIndex(
        (message) => message._id === payload?.messageId
      );
      console.log('message index', messageIndex);
      if (messageIndex >= 0) {
        state.messages[messageIndex].flag = MESSAGE_FLAG.DELETE;
      }

      // });
    },

    setLoadingContactStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingContactData = action.payload;
    },
  },
});

export const {
  setContinueScrollStartM,
  setTotalMessageCount,
  setCurrentChatMessages,
  setCurrentChatContact,
  updateOneToOneDeletedMessage,
  setIsNeedToSyncNow,
  addPaginatedMessage,
  populateMessages,
  setOneToOneMsgEndStatus,
  editOneToOneMessage,
  pushMessage,
  updateOnetoOneMsg,
  setCurrentChatTab,
  setLoadingContactStatus,
  setLoadingOneToOneMsgStatus,
} = currentChatSlice.actions;

export default currentChatSlice.reducer;
