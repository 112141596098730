import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setUser } from 'store/userReducer';
import useSetting from './useSetting';
import { STORAGE } from 'utils/constants';
import { getCredentials } from 'utils/auth';

const useProfile = () => {
  const user = useSelector((state: RootState) => state.user.value);
  const dispatch = useDispatch();

  return {
    setUserProfile: function (user: any) {
      dispatch(setUser(user));
    },
    userProfile: user,
  };
};

const useUserData = () => {
  const { userProfile } = useProfile();
  // console.log('userProfile', userProfile);
  const {
    setting,
    setOnlineStatusForMyAccount,
    setNotificationStatusForAppSetting,
  } = useSetting();
  const [state, setState] = useState<{
    userId: string;
    friendId: string;
    globalUsername: string;
    userToken: string;
    userPhone: string;
    image: string;
    profileImageUrl: string;
    globalImage: string;
    userNotification: null | boolean;
    userBusy: null | boolean;
    username: string;
  }>({
    userId: '',
    friendId: '',
    globalUsername: '',
    userToken: '',
    userPhone: '',
    image: '',
    profileImageUrl: '',
    globalImage: '',
    userNotification: null,
    userBusy: false,
    username: '',
  });

  useEffect(() => {
    if (userProfile) {
      setState({
        ...state,
        userId: userProfile._id,
        friendId: userProfile.user_id,
        globalUsername: userProfile.name,
        userToken: userProfile.accessToken,
        userPhone: userProfile.phone,
        image: userProfile.image,
        profileImageUrl: userProfile.profileImageUrl || undefined,
        globalImage:
          userProfile.image != undefined
            ? userProfile.image
            : 'default_image.jpg',
        username: userProfile.name,
        userBusy: userProfile.user_busy,
      });
    }
  }, [userProfile]);

  // useEffect(() => {
  //   const storedData = getCredentials();
  //   if (storedData.data && storedData.data.data && storedData.data.data.user) {
  //     setOnlineStatusForMyAccount(storedData.data.data.user.user_busy == '0'); // user busy - 0 means that user is not busy now.
  //     setNotificationStatusForAppSetting(
  //       storedData.data.data.user.notification == '1' // noti - 1 means that noti is on.
  //     );
  //   }
  // }, []);

  return state;
};

export { useProfile, useUserData };
