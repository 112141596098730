import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  value: any;
}

const initialState: UserState = {
  value: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    logoutStore: (state, action) => {},
  },
});

export const { setUser, logoutStore } = userSlice.actions;

export default userSlice.reducer;
