import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessage } from 'models/CurrentChatModel.type';
import { GroupChatMessage } from 'models/CurrentGroupChatModel.type';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MediaPayload } from './groupAttachedFileReducer';
import { MediaPayload as oneToOneMediaPyaload } from './oneToOneAttachFileReducer';
import { MESSAGE_FLAG } from 'utils/constants';

interface SelectedMessage {
  id: string;
  message: string;
}

export interface MessagesToDelete {
  msgIdArr: {messageId:string ,senderId:string}[];
  groupId: string;
  alsoBuddy: number;
}
export interface oneToOneMessageToDelete {
  messageId: string;
  receiverId: string;
  flag?: MESSAGE_FLAG | string;
  message: string;
}

export interface mediaToDelete {
  _id: string[];
  // receiverId:string;
}

interface SelectMessageStateTypes {
  selectedMessages: (GroupChatMessage | ChatMessage)[];
  messagesToDelete: MessagesToDelete | null;
  oneToOneMessageToDelete: oneToOneMessageToDelete | null;
  mediaToDelete: mediaToDelete | null;
  messagesToForward: (
    | GroupChatMessage
    | ChatMessage
    | MediaPayload
    | oneToOneMediaPyaload
  )[];
  showScrollButton: boolean;
  selectingMessages: boolean;
}

const initialState: SelectMessageStateTypes = {
  selectedMessages: [],
  messagesToDelete: null,
  oneToOneMessageToDelete: null,
  messagesToForward: [],
  showScrollButton: false,
  selectingMessages: false,
  mediaToDelete: null,
};

export const selectMessageSlice = createSlice({
  name: 'selectMessage',
  initialState,
  reducers: {
    setSelectedMessage: (
      state,
      action: PayloadAction<(GroupChatMessage | ChatMessage)[] | []>
    ) => {
      state.selectedMessages = action.payload;
    },
    pushSelectedMessage: (
      state,
      action: PayloadAction<GroupChatMessage | ChatMessage>
    ) => {
      state.selectedMessages.push(action.payload);
    },
    popSelectedMessage: (
      state,
      action: PayloadAction<GroupChatMessage | ChatMessage>
    ) => {
      const payload = action.payload;
      const messageIndex = state.selectedMessages.findIndex(
        (message) => message._id === payload._id
      );

      if (messageIndex >= 0) {
        state.selectedMessages.splice(messageIndex, 1);
      }
    },

    setMessagesToDelete: (
      state,
      action: PayloadAction<MessagesToDelete | null>
    ) => {
      state.messagesToDelete = action.payload;
    },
    setMediaToDelete: (state, action: PayloadAction<mediaToDelete | null>) => {
      state.mediaToDelete = action.payload;
    },

    setOneToOneMessagesToDelete: (
      state,
      action: PayloadAction<oneToOneMessageToDelete | null>
    ) => {
      state.oneToOneMessageToDelete = action.payload;
    },

    setMessagesToForward: (
      state,
      action: PayloadAction<
        (GroupChatMessage | ChatMessage | MediaPayload | oneToOneMediaPyaload)[]
      >
    ) => {
      state.messagesToForward = action.payload;
    },
    setOneToOneMessagesToForward: (
      state,
      action: PayloadAction<(GroupChatMessage | ChatMessage | MediaPayload)[]>
    ) => {
      state.messagesToForward = action.payload;
    },

    setShowScrollButton: (state, action: PayloadAction<boolean>) => {
      state.showScrollButton = action.payload;
    },
    setSelectingMessages: (state, action: PayloadAction<boolean>) => {
      state.selectingMessages = action.payload;
    },
  },
});

export const {
  setSelectedMessage,
  pushSelectedMessage,
  popSelectedMessage,
  setMessagesToDelete,
  setMediaToDelete,
  setOneToOneMessagesToDelete,
  setMessagesToForward,
  setShowScrollButton,
  setSelectingMessages,
} = selectMessageSlice.actions;

export default selectMessageSlice.reducer;

export const useSelectMessageState = () => {
  const selectedMessageState: SelectMessageStateTypes = useSelector(
    (state: RootState) => state.selectMessageState
  );

  return selectedMessageState;
};
