import { io } from 'socket.io-client';
import { getCredentials } from './auth';

export const getSocketObj = () => {
  const bearerToken = getCredentials();
  // console.log(bearerToken);
  return io(process.env.REACT_APP_API_BASE_URL || '', {
    transports: ['polling'],
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${bearerToken.token}`,
        },
      },
    },
  });
};
