import { useEffect } from 'react';
import { Contact } from 'models/AppStateModel.type';
import { CurrentChatContact } from 'models/CurrentChatModel.type';
import {
  CALL_TYPE,
  CHAT_MESSAGE_STATE,
  MESSAGE_FLAG,
  STORAGE,
} from 'utils/constants';
import { useUserData } from './useProfile';
import { useGetChatLatestMessages } from './useSocketListener';
import { useWebSocket } from './useWebSocket';
import { useCallback } from 'react';
import useCurrentChat from './useCurrentChat';
import {
  setCurrentChatContact,
  setIsNeedToSyncNow,
} from 'store/currentChatReducer';
import { useDispatch, useSelector } from 'react-redux';
import useAppState from './useAppState';
import { RootState } from 'store';
import { setChatSettingOpenState } from 'store/chatSettingReducer';
import { updateOneToOneLists } from 'store/appReducer';

export function useUserJoinSocket() {
  const { userToken, userId, userBusy } = useUserData();
  const socket = useWebSocket();
  const { appState } = useAppState();

  useEffect(() => {
    if (!appState.socketNeedToSync) return;
    console.log('user connect');
    if (userToken) {
      socket.emit('new-user-joined', { userId, userBusy });
      socket.emit('userData', { userId, user_token: userToken });
    }
  }, [socket, userToken, userId, userBusy, appState.socketNeedToSync]);
}

export function useUserLogSocketEmitter() {
  const { userToken, userId } = useUserData();
  const socket = useWebSocket();

  const getLoginUserInfoEmitter = (token = userToken) => {
    console.log('getLoginUserInfo');
    socket.emit('currentUser', { user_token: token });
  };

  const userDisconnect = () => {
    console.log('userDisconnect');
    socket.emit('user-disconnect', {
      userId,
    });
  };
  return { getLoginUserInfoEmitter, userDisconnect };
}

export function useClickOneToOneChat() {
  const socket = useWebSocket();
  const { currentChat } = useCurrentChat();
  const { userId, userToken } = useUserData();
  const getChatLatestMessages = useGetChatLatestMessages();

  const clickOneToOneChat = useCallback(
    ({
      user_id = userId,
      receiverId,
      startm = 0,
    }: {
      user_id?: string;
      receiverId: string;
      startm?: number;
    }) => {
      console.log('clickOnetoOneChat', user_id, receiverId, startm);

      if (userToken) {
        sessionStorage.setItem(STORAGE.CURRENT_CHAT_RECEIVER, receiverId);
        socket.emit('contactByUser', {
          userId: receiverId,
          created_by: user_id,
          startm: startm,
        });

        // socket.emit('chat_online', { id: receiverId });
        socket.emit('userClick', {
          userId,
          receiverId,
          startm,
        });

        getChatLatestMessages();
      }
    },
    [
      // currentChat.startM,
      userToken,
      socket,
      userId,
      currentChat,
      getChatLatestMessages,
    ]
  );

  return clickOneToOneChat;
}

export function useSendMessageSockets() {
  const socket = useWebSocket();
  const { userId, profileImageUrl, username } = useUserData();
  const dispatch = useDispatch();

  const sendMessageTyping = useCallback(
    ({
      isTyping,
      receiverId,
      Image,
      nick,
    }: {
      isTyping: boolean;
      receiverId: string;
      Image: string;
      nick: string;
    }) => {
      // console.log('typing');
      // return;
      socket.emit('typing', {
        isTyping,
        nick: username,
        Image: profileImageUrl,
        receiverId,
        senderId: userId,
      });
    },
    [socket, userId]
  );

  const sendOneToOneMessage = useCallback(
    ({
      message,
      receiver_id = '',
      file_upload,
      file_size = 0,
      flag,
      contactId,
      replyId,
      replySenderId = '',
      duration = 0,
      missed = 0,
      tempMsgId,
    }: {
      message?: string;
      receiver_id?: string;
      file_upload?: string;
      file_size?: number;
      flag: MESSAGE_FLAG;
      contactId: string;
      replyId?: string;
      replySenderId?: string;
      missed?: number;
      duration?: number;
      tempMsgId?: string;
    }) => {
      console.log('contactId', contactId);
      console.log('receiver_id', receiver_id);
      socket.emit('chat message', {
        message,
        sender_id: userId,
        sender_name: username,
        sender_image: profileImageUrl,
        receiver_id,
        file_upload,
        file_size,
        flag,
        contactId,
        reply_id: replyId,
        reply_sender_id: replySenderId,
        missed,
        duration,
        tempMsgId,
      });
      dispatch(setIsNeedToSyncNow(true));
    },
    [socket, userId, username, profileImageUrl]
  );

  const updateMessage = useCallback(
    ({
      messageId,
      message,
      receiveId,
      flag = MESSAGE_FLAG.EDIT,
    }: // contactId,
    {
      messageId: string;
      message: string;
      receiveId: string;
      // userId: string ;
      flag?: MESSAGE_FLAG.EDIT;
    }) => {
      // console.log('updateMessage');

      socket.emit('message_update', {
        messageId,
        message,
        flag,
        receiveId,
        userId,
      });
    },
    [userId, socket]
  );

  return {
    sendOneToOneMessage,
    sendMessageTyping,
    updateMessage,
  };
}

export function useMessageSeenEmitter() {
  const socket = useWebSocket();

  const readMessage = (payload: { messageId: string }) => {
    socket.emit('readMessage', payload);
  };

  return { readMessage };
}

export function useSearchFriends() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const searchForChatAllFriends = (searchStr: string) => {
    console.log('searchForChatAllFriends');
    const searchVal = searchStr.toUpperCase();
    socket.emit('searchContactValue', { searchVal, userId });
  };

  const searchForChatActiveFriends = (searchStr: string) => {
    const searchVal = searchStr.toUpperCase();
    socket.emit('searchOContactValue', { searchVal, userId });
  };

  return {
    searchForChatAllFriends,
    searchForChatActiveFriends,
  };
}

export function useHandleSetting() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const updateNotificationStatusForChatSetting = (status: 0 | 1) => {
    console.log('NotiUpdate::', status);
    socket.emit('userMutedNotification', {
      user_id: userId,
      is_muted: status,
    });
  };

  const updateOnlineStatusForMyAccount = (status: 0 | 1) => {
    console.log('OnlineUpdate', status);
    socket.emit('userbusyUpdate', {
      user_id: userId,
      user_busy: status,
    });
  };

  return {
    updateNotificationStatusForChatSetting,
    updateOnlineStatusForMyAccount,
  };
}

export function useChatMenuHandler() {
  const socket = useWebSocket();
  const { userId } = useUserData();
  const dispatch = useDispatch();

  const currentChatContact = useSelector(
    (state: RootState) => state.currentChat.contact
  );

  const markAsUnread = ({
    receiverId,
    senderId,
  }: {
    receiverId: string;
    senderId: string;
  }) => {
    console.log('markAsUnread');

    socket.emit('markAsUnread', {
      receiverId: receiverId,
      senderId: senderId,
    });
  };

  const markAsRead = ({
    receiverId,
    senderId,
  }: {
    receiverId: string;
    senderId: string;
  }) => {
    console.log('markAsRead');

    socket.emit('markAsRead', {
      receiverId: receiverId,
      senderId: senderId,
    });
  };

  const contactMutedNotification = useCallback(
    ({
      contactId,
      isMuted,
      receiverId,
      user_id = userId,
    }: {
      contactId: string;
      isMuted: 1 | 0;
      receiverId: string;
      user_id?: string;
    }) => {
      console.log('isMuted', isMuted);
      socket.emit('contactMutedNotification', {
        contact_id: contactId,
        is_muted: isMuted.toLocaleString(),
        receiver_id: receiverId,
        userId: user_id,
      });
    },
    [userId]
  );

  const roomChatHistoryDelete = useCallback(
    ({
      receiverId,
      user_id = userId,
      alsoBuddy,
    }: {
      receiverId: string;
      user_id?: string;
      alsoBuddy: boolean;
    }) => {
      socket.emit('all_Message_delete', {
        receiverId,
        userId: user_id,
        alsoBuddy,
      });
    },
    [userId, socket, currentChatContact]
  );

  return {
    markAsUnread,
    markAsRead,
    roomChatHistoryDelete,
    contactMutedNotification,
  };
}

export function useDeleteMessageHandler() {
  const socket = useWebSocket();
  const { userId } = useUserData();

  const singleMessageDelete = useCallback(
    ({
      messageId,
      receiverId,
      message,
      alsoBuddy,
    }: {
      messageId: string;
      receiverId: string;
      message?: string;
      alsoBuddy: '1' | '0';
    }) => {
      console.log(alsoBuddy);
      socket.emit('message_delete', {
        message_id: messageId,
        receiverId,
        message,
        userId,
        flag: alsoBuddy == '1' ? '2' : userId,
        alsoBuddy: Number(alsoBuddy),
      });
    },
    [userId, socket]
  );

  // const singleMessageDeleteForU = useCallback(
  //   ({ messageId }: { messageId: string }) => {
  //     socket.emit('message_delete_foru', {
  //       message_id: messageId,
  //       flag: userId,
  //     });
  //   },
  //   []
  // );

  return {
    singleMessageDelete,
    // singleMessageDeleteForU,
  };
}

export const useSendForwardMessage = () => {
  const { userId } = useUserData();
  const socket = useWebSocket();

  const sendForwardMessage = useCallback(
    ({
      message = '',
      contact,
      fileName = '',
      fileSize = 0,
      flag = MESSAGE_FLAG.FORWARD,
    }: {
      message?: string;
      contact: Contact;
      fileName?: string;
      fileSize?: number;
      flag?: MESSAGE_FLAG;
    }) => {
      socket.emit('chat message', {
        message: message,
        sender_id: userId,
        receiver_id: contact.receiver_id,
        file_upload: fileName,
        file_size: fileSize,
        flag,
        contactId: contact._id,
      });
    },
    [userId, socket]
  );

  return sendForwardMessage;
};
