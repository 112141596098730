import JitsiCall from 'components/Call/Jitsi/JitsiCall';
import { useCallEnd } from 'hooks/useCallSocketEmitter';
import { useWebSocket } from 'hooks/useWebSocket';
import { localCallDataType } from 'models/DialingStateModel.type';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

function Call() {
  const [callData, setCallData] = React.useState<localCallDataType | null>(
    null
  );
  const socket = useWebSocket();
  const [searchParams, setSearchParams] = useSearchParams();
  const callParam = searchParams.get('type');

  React.useEffect(() => {
    const localCallData = JSON.parse(
      localStorage.getItem('call_data') || 'null'
    );
    if (localCallData) setCallData(localCallData);
    socket.off('callRinging');
  }, []);

  useEffect(() => {
    console.log(callData);
  }, [callData]);

  // useEffect(() => {
  //   const handleUnload = () => {
  //     if(callData)
  //     callEnd({ callEndedTo: callData?.subjectId, callEndedBy: callData?.userId, callType: callData?.callType });
  //   };

  //   window.addEventListener('visibilitychange', handleUnload);

  //   return () => {
  //     window.removeEventListener('visibilitychange', handleUnload);
  //   };
  // }, [callEnd]);

  if (callData)
    return (
      <>
        <Helmet>
          <title>Call | TeaTalk</title>
        </Helmet>
        <JitsiCall
          username={callData?.username}
          userId={callData?.userId}
          userImage={callData?.userImage}
          subjectName={callData?.subjectName}
          subjectId={callData?.subjectId}
          groupName={callData?.groupName}
          groupId={callData?.groupId}
          callFromUserId={callData?.callFromUserId}
          callType={callData?.callType}
          callConfig={callData.callConfig}
          jitsi={callData?.jitsi}
          callParam={callParam || ''}
        />
      </>
    );
  return <></>;
}

export default Call;
