import Logo from "../components/common/Logo/Logo";
import LoginForm from "../components/Login/LoginForm/LoginForm";
import { Helmet } from 'react-helmet';

function Login() {

    return (
        <>
            <Helmet>
                <title>Log in | TeaTalk</title>
            </Helmet>

            <div className="layoutPadding w-full h-[100vh] grid grid-cols-1 md:grid-cols-2 bg-background gap-10">
                <div className="w-full h-full hidden md:flex items-center justify-center">
                    <Logo width="w-[80%]" height="h-auto" />
                </div>

                <LoginForm />
            </div>
        </>
    );
}

export default Login;