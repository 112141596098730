import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useSendMessageSockets } from './useSingleSocketEmitter';
import { useUserData } from './useProfile';
import { localCallDataType } from 'models/DialingStateModel.type';
import { CALL_TYPE, MESSAGE_FLAG } from 'utils/constants';
import {
  removeGroupCallParticipant,
  setCallTimer,
  setCurrentlyOnCall,
  setStartCallTimer,
} from 'store/dialingReducer';
import { useSendGroupMessage } from './useGroupSocketEmitter';
import { pushMessage } from 'store/currentChatReducer';
import { oneToOneMessageDefault } from 'models/CurrentChatModel.type';
import { generateKey } from 'crypto';
import { generateTempId } from 'helpers/generateTempId';
import { pushGroupMessage } from 'store/currentGroupChatReducer';
import { groupMessageDefault } from 'models/CurrentGroupChatModel.type';

function useCallDisconnectHandler() {
  const { currentlyOnCall, callTimer, groupCallParticipants } = useSelector(
    (state: RootState) => state.dialing
  );
  const dispatch = useDispatch();
  const { sendOneToOneMessage } = useSendMessageSockets();
  const { sendGroupMessage } = useSendGroupMessage();

  const { userId } = useUserData();

  const handleCallDisconnect = ({
    disconnected_userId,
  }: {
    disconnected_userId: string;
  }) => {
    if (!currentlyOnCall) return;
    if (disconnected_userId !== userId) return;

    const localCallData = JSON.parse(
      localStorage.getItem('call_data') || 'null'
    ) as localCallDataType;

    if (!localCallData) return;

    // Handle groupcall disconnect
    if (localCallData?.groupId) {
      const disconnectedUserIndex = groupCallParticipants.findIndex(
        (participant) => participant.id === disconnected_userId
      );

      if (disconnectedUserIndex >= 0) {
        dispatch(removeGroupCallParticipant({ id: disconnected_userId }));
      }
      console.log(disconnected_userId, userId, localCallData);

      if (userId === localCallData.callFromUserId) {
        //send group call log
        let tempMsgId = generateTempId();
        dispatch(
          pushGroupMessage({
            ...groupMessageDefault,
            _id: tempMsgId,
            createdAt: new Date().toDateString(),
            group_id: localCallData.groupId,
            flag:
              localCallData.callType === CALL_TYPE.AUDIO
                ? MESSAGE_FLAG.AUDIO
                : MESSAGE_FLAG.VIDEO,
            name: [localCallData.username],
            read_users: [
              {
                name: localCallData.username,
                userId,
                profileImage: localCallData.userImage,
                readAt: 0,
              },
            ],
            sender_id: userId,
            sending: true,
            tempMsgId,
            missed: 0,
            duration: callTimer * 1000,
          })
        );

        sendGroupMessage({
          group_id: localCallData.groupId,
          flag:
            localCallData.callType === CALL_TYPE.AUDIO
              ? MESSAGE_FLAG.AUDIO
              : MESSAGE_FLAG.VIDEO,
          missed: 0,
          duration: callTimer * 1000,
        });
      }
    } else {
      // Handle single call disconnect
      if (
        disconnected_userId === localCallData?.subjectId ||
        disconnected_userId === localCallData?.callFromUserId
      ) {
        console.log(callTimer);
        if (localCallData.callFromUserId === userId) {
          //send call log
          let tempMsgId = generateTempId();

          dispatch(
            pushMessage({
              ...oneToOneMessageDefault,
              _id: tempMsgId,
              flag:
                localCallData?.callType === CALL_TYPE.AUDIO
                  ? MESSAGE_FLAG.AUDIO
                  : MESSAGE_FLAG.VIDEO,
              createdAt: new Date().toLocaleString(),
              name: localCallData.username,
              sender_id: userId,
              receiver_id: localCallData?.subjectId || '',
              sending: true,
              tempMsgId,
              missed: 0,
              duration: callTimer * 1000,
            })
          );

          sendOneToOneMessage({
            flag:
              localCallData.callType === CALL_TYPE.AUDIO
                ? MESSAGE_FLAG.AUDIO
                : MESSAGE_FLAG.VIDEO,
            receiver_id: localCallData.subjectId,
            contactId: localCallData.subjectContactId!,
            missed: 0,
            duration: callTimer * 1000,
          });
        }
      }
    }

    dispatch(setCurrentlyOnCall(false));
    dispatch(setStartCallTimer(false));
    dispatch(setCallTimer({ mode: 'set', value: 0 }));
    localStorage.removeItem('call_data');
  };

  return { handleCallDisconnect };
}

export default useCallDisconnectHandler;
