import Lottie from "lottie-react";
import loader from "./../../../utils/loader.json";

function PageLoader() {
    return ( 
        <div className="fixed z-30 w-full h-[100vh] top-0 left-0 flex items-center justify-center bg-[#ffffffa1]">
            <div className="w-[200px] h-[200px]">
                <Lottie animationData={loader} loop={true} />
            </div>
        </div>
    );
}

export default PageLoader;