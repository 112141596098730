import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Login from './../pages/Login';
import AuthProvider from 'providers/AuthProvider';
import NotRequiredAuth from './NotRequiredAuth';
import { Suspense, lazy } from 'react';
import Call from 'pages/Call';
import LogoLoadingScreen from 'components/common/LoadingScreen/LogoLoadingScreen';
import SpinLoadingScreen from 'components/common/LoadingScreen/SpinLoadingScreen';

const Chat = lazy(() => import('./../pages/Chat'));
const RequiredAuth = lazy(() => import('./RequiredAuth'));

function AppRoutes() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <>
            <Route
              path="/login"
              element={
                <NotRequiredAuth>
                  <Login />
                </NotRequiredAuth>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<LogoLoadingScreen />}>
                  <RequiredAuth>
                    <Chat />
                  </RequiredAuth>
                </Suspense>
              }
            />
            <Route
              path="/call"
              element={
                <Suspense fallback={<SpinLoadingScreen />}>
                  <RequiredAuth>
                    <Call />
                  </RequiredAuth>
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default AppRoutes;
