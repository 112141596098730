export enum ROUTE_NAMES {
  FRESH_FLASH = 'Fresh Flash',
  ACTIVE = 'Active',
  CHAT_HISTORY = 'Chats',
  CHAT_GROUP_NEW = 'New',
  SETTINGS = 'Settings',
  LOG_OUT = 'Sign out',
  ADD_MEMBERS = 'Add Members',
}

export enum STATUS {
  ALL = 'See all buddies',
  ACTIVE = 'Active buddies',
}

export enum CHAT_TYPE {
  SINGLE = 'Single',
  GROUP = 'Group',
}

export enum STORAGE {
  CURRENT_USER = 'currentUser',
  ACCESS_TOKEN = 'teatalk_access_token',
  CURRENT_CHAT_RECEIVER = 'currentChatReceiver',
  NOTI_MSG_ID = 'noti_msg_id',
  NOTI_PERMISSION_STATUS = 'noti_permission_status',
}

export enum MESSAGE_FLAG {
  NORMAL = '0',
  EDIT = '1',
  DELETE = '2',
  FORWARD = '3',
  AUDIO = '4',
  VIDEO = '5',
}

export enum MESSAGE_PAGI {
  LIMIT = 10,
}

export enum RESOURCE_PAGI {
  LIMIT = 10,
}

export enum CHAT_MESSAGE_STATE {
  UNREAD = 0,
  READ = 1,
  UNMUTED = '0',
  MUTED = '1',
}

export enum ACTION_TYPE {
  DANGER = 'danger',
  SAFE = 'safe',
  NEUTRAL = 'neutral',
}

export enum CALL_TYPE {
  AUDIO = 'audio',
  VIDEO = 'video',
}

export enum DIALING_TYPE {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export const DEFAULT_IMAGE = 'default_image.jpg';

export enum MESSAGE_OWNER {
  SELF = 'self',
  OTHER = 'other',
}
