import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact } from 'models/AppStateModel.type';
import { ChatMessage, CurrentChatContact } from 'models/CurrentChatModel.type';
import {
  CurrentGroupChatContact,
  CurrentGroupChatMembers,
  GroupChatMessage,
  groupMessageDefault,
  GroupOnlineUsers,
  SeenMembers,
} from 'models/CurrentGroupChatModel.type';
import { CHAT_TYPE, MESSAGE_FLAG, MESSAGE_PAGI } from 'utils/constants';

export interface currentGroupChatState {
  groupContact: CurrentGroupChatContact | null;
  groupOnlineUsers: GroupOnlineUsers;
  messages: GroupChatMessage[];
  lastMessage: GroupChatMessage | null;
  messageEnd: boolean;
  totalMessageCount: number;
  loadingGroupData: boolean;
  loadingOldMessage: boolean;
}

const initialState: currentGroupChatState = {
  groupOnlineUsers: { count: 0, users: [] },
  groupContact: null,
  messages: [],
  messageEnd: false,
  lastMessage: null,
  totalMessageCount: 0,
  loadingGroupData: false,
  loadingOldMessage: false,
};

export const currentGroupChatSlice = createSlice({
  name: 'currentGroupChat',
  initialState,
  reducers: {
    setCurrentGroupContact: (
      state,
      action: PayloadAction<CurrentGroupChatContact | null>
    ) => {
      state.groupContact = action.payload;
    },
    updateCurrentGroupContact: (
      state,
      action: PayloadAction<Partial<CurrentGroupChatContact>>
    ) => {
      if (!state.groupContact) return;

      state.groupContact = {
        ...state.groupContact,
        ...action.payload,
      };
    },
    setLoadingGroupData: (state, action: PayloadAction<boolean>) => {
      state.loadingGroupData = action.payload;
    },
    setLoadingGroupOldMsgStatus: (state, action: PayloadAction<boolean>) => {
      state.loadingOldMessage = action.payload;
    },
    setGroupTotalMessageCount: (state, action: PayloadAction<number>) => {
      state.totalMessageCount = action.payload;
    },
    setGroupOnlineUsers: (state, action) => {
      state.groupOnlineUsers = action.payload;
    },
    setGroupMessagEndStatus: (state, action: PayloadAction<boolean>) => {
      state.messageEnd = action.payload;
    },
    populateMessages: (state, action: PayloadAction<GroupChatMessage[]>) => {
      if (action.payload.length < 10) {
        state.messageEnd = true;
      } else {
        state.messageEnd = false;
      }

      if (action.payload.length === 0) {
        state.messages = [];
      } else {
        if (state.groupContact?._id === action.payload[0].group_id)
          state.messages = action.payload;
      }
    },

    addPaginatedMessage: (state, action: PayloadAction<GroupChatMessage[]>) => {
      if (action.payload.length < 10) {
        state.messageEnd = true;
      } else {
        state.messageEnd = false;
      }

      if (action.payload.length === 0) return;

      if (state.groupContact?._id !== action.payload[0].group_id) return;

      state.messages.push(...action.payload);
    },
    pushGroupMessage: (state, action: PayloadAction<GroupChatMessage>) => {
      state.messages.unshift(action.payload);
      state.lastMessage = action.payload;
    },

    updateGroupMessage: (
      state,
      action: PayloadAction<Partial<GroupChatMessage>>
    ) => {
      const payload = action.payload;
      console.log(payload);
      console.log(state.messages[0]);

      const msgIndex = state.messages.findIndex(
        (message) => message.tempMsgId == payload.tempMsgId || 0
      );

      console.log(msgIndex);

      if (msgIndex >= 0) {
        state.messages[msgIndex] = { ...groupMessageDefault, ...payload };
      }
    },

    editGroupMessage: (
      state,
      action: PayloadAction<{
        id: string;
        editedMsg: string;
        flag: MESSAGE_FLAG;
      }>
    ) => {
      const payload = action.payload;
      const msgIndex = state.messages.findIndex(
        (message) => message._id === payload.id
      );
      if (msgIndex >= 0) {
        state.messages[msgIndex].message = payload.editedMsg;
        state.messages[msgIndex].flag = payload.flag;
      }
    },

    setCurrentGroupMembers: (
      state,
      action: PayloadAction<CurrentGroupChatMembers[]>
    ) => {
      if (state.groupContact) {
        state.groupContact.members = action.payload;
      }
    },
    updateCurrentGroupMemberStatus: (
      state,
      action: PayloadAction<{ user_id: string; is_active: boolean }>
    ) => {
      const payload = action.payload;

      if (!state.groupContact) return;
      const memberIndex = state.groupContact?.members.findIndex(
        (member) => member.user_id[0] === payload?.user_id
      );
      if (memberIndex >= 0) {
        if (
          payload.is_active &&
          !state.groupContact?.members[memberIndex].is_active[0]
        ) {
          state.groupOnlineUsers.count = state.groupOnlineUsers.count + 1;
        } else if (
          !payload.is_active &&
          state.groupContact.members[memberIndex].is_active[0]
        ) {
          state.groupOnlineUsers.count = state.groupOnlineUsers.count - 1;
        }

        state.groupContact.members[memberIndex].is_active = [payload.is_active];
      }
    },
    updateMessageReadList: (
      state,
      action: PayloadAction<{ messageId: string; readUsers: [] }>
    ) => {
      const payload = action.payload;

      const messageIndex = state.messages.findIndex(
        (message) => message._id === payload.messageId
      );

      if (messageIndex >= 0) {
        state.messages[messageIndex].read_users = payload.readUsers;
      }
    },

    updateGroupDeletedMessage: (
      state,
      action: PayloadAction<{
        alsoBuddy: number;
        groupId: string;
        msgIdArr: string[];
        userId: string;
        deleteforme: string[];
      }>
    ) => {
      const payload = action.payload;

      payload.msgIdArr.forEach((msgId) => {
        const messageIndex = state.messages.findIndex(
          (message) => message._id === msgId
        );
        if (messageIndex >= 0) {
          if (payload.deleteforme.length === 0) {
            state.messages[messageIndex].flag = MESSAGE_FLAG.DELETE;
          } else {
            state.messages[messageIndex].deleteforme = payload.deleteforme;
          }
        }
      });
    },
  },
});

export const {
  setCurrentGroupContact,
  updateCurrentGroupContact,
  setLoadingGroupData,
  setGroupTotalMessageCount,
  setGroupOnlineUsers,
  updateCurrentGroupMemberStatus,
  populateMessages,
  setLoadingGroupOldMsgStatus,
  pushGroupMessage,
  setGroupMessagEndStatus,
  updateGroupMessage,
  editGroupMessage,
  addPaginatedMessage,
  updateMessageReadList,
  updateGroupDeletedMessage,
  setCurrentGroupMembers,
} = currentGroupChatSlice.actions;

export default currentGroupChatSlice.reducer;
