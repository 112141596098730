import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomUser } from 'models/AppStateModel.type';

export interface RoomState {
    roomUserForAction : RoomUser | null
}

const initialState: RoomState = {
  roomUserForAction: null
};

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoomUserForAction: (state, action: PayloadAction<RoomUser | null>) => {
      state.roomUserForAction = action.payload;
    },
  },
});

export const {
setRoomUserForAction
} = roomSlice.actions;

export default roomSlice.reducer;
