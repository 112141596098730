import { Contact } from 'models/AppStateModel.type';
import { ChatMessage, CurrentChatContact } from 'models/CurrentChatModel.type';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  setCurrentChatTab,
  setCurrentChatContact,
  setCurrentChatMessages,
  setIsNeedToSyncNow,
  setContinueScrollStartM,
  setTotalMessageCount,
} from 'store/currentChatReducer';
import { CHAT_TYPE } from 'utils/constants';

const useCurrentChat = () => {
  const currentChat = useSelector((state: RootState) => state.currentChat);
  const dispatch = useDispatch();

  return {
    setIsNeedToSyncNow: (value: boolean) => {
      dispatch(setIsNeedToSyncNow(value));
    },
    setContinueScrollStartM: (value: boolean) => {
      dispatch(setContinueScrollStartM(value));
    },
    setTotalMessageCount: (value: number) => {
      dispatch(setTotalMessageCount(value));
    },
    setCurrentChatMessages: (messages: ChatMessage[]) => {
      dispatch(setCurrentChatMessages(messages));
    },

    setCurrentChatContact: (contact: CurrentChatContact | null) => {
      dispatch(setCurrentChatContact(contact));
    },

    setCurrentChatTab: (type: CHAT_TYPE) => {
      dispatch(setCurrentChatTab(type));
    },

    currentChat,
  };
};

export default useCurrentChat;
