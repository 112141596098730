import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CallRingingToType,
  CallRingingType,
  GroupCallParticipant,
  CurrentGroupCall,
} from 'models/DialingStateModel.type';
import { CALL_TYPE } from 'utils/constants';

interface DialingType {
  currentlyOnCall: boolean;
  callRinging: CallRingingType | null;
  callRingingTo: CallRingingToType | null;
  currentGroupCall: CurrentGroupCall | null;
  groupCallParticipants: GroupCallParticipant[];
  callTimer: number;
  startCallTimer: boolean;
}

const initialState: DialingType = {
  currentlyOnCall: false,
  callRinging: null,
  callRingingTo: null,
  currentGroupCall: null,
  groupCallParticipants: [],
  callTimer: 0,
  startCallTimer: false,
};

const dialingSlice = createSlice({
  name: 'dialing',
  initialState,
  reducers: {
    setCurrentlyOnCall: (state, action: PayloadAction<boolean>) => {
      state.currentlyOnCall = action.payload;
    },

    setCallRinging: (state, action: PayloadAction<CallRingingType | null>) => {
      state.callRinging = action.payload;
    },
    setCallRingingTo: (
      state,
      action: PayloadAction<CallRingingToType | null>
    ) => {
      state.callRingingTo = action.payload;
    },
    setCurrentGroupCall: (
      state,
      action: PayloadAction<CurrentGroupCall | null>
    ) => {
      state.currentGroupCall = action.payload;
    },
    setGroupCallParticipants: (
      state,
      action: PayloadAction<GroupCallParticipant[]>
    ) => {
      state.groupCallParticipants = action.payload;
    },
    addGroupCallParticipant: (
      state,
      action: PayloadAction<GroupCallParticipant>
    ) => {
      state.groupCallParticipants.push(action.payload);
    },
    removeGroupCallParticipant: (
      state,
      action: PayloadAction<Partial<GroupCallParticipant>>
    ) => {
      const participantIndex = state.groupCallParticipants.findIndex(
        (participant) => participant.id === action.payload.id
      );
      if (participantIndex >= 0) state.groupCallParticipants.slice();
    },
    setCallTimer: (
      state,
      action: PayloadAction<{
        mode: 'increment' | 'decrement' | 'set';
        value?: number;
      }>
    ) => {
      const payload = action.payload;

      if (payload.mode === 'set') state.callTimer = payload.value || 0;
      if (payload.mode === 'increment') state.callTimer = state.callTimer + 1;
      if (payload.mode === 'decrement') state.callTimer = state.callTimer - 1;
    },
    setStartCallTimer: (state, action: PayloadAction<boolean>) => {
      state.startCallTimer = action.payload;
    },
  },
});

export const {
  setCurrentlyOnCall,
  setCallRinging,
  setCallRingingTo,
  setCurrentGroupCall,
  setGroupCallParticipants,
  addGroupCallParticipant,
  removeGroupCallParticipant,
  setCallTimer,
  setStartCallTimer,
} = dialingSlice.actions;

export default dialingSlice.reducer;
