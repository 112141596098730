import api from '../utils/api';
import { LoginFormInterface } from '../utils/interfaces';

export const login = async (body: LoginFormInterface) => {
  console.log('Constructed URL:', api.defaults.baseURL + '/users/signin'); // Log the final URL

  const res = await api.post('/users/signin', body);

  return res;
};

export const logoutApi = async () => {
  const res = await api.get('/users/logout');

  return res;
};
