import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateObject } from 'helpers/objectHelpers';
import {
  AppStatus,
  Contact,
  RoomGroup,
  RoomUser,
  Typing,
} from 'models/AppStateModel.type';
import { LastMessage, TypingMembers } from 'models/CurrentGroupChatModel.type';
import { CHAT_TYPE, ROUTE_NAMES } from 'utils/constants';

export interface AppState {
  socketNeedToSync: boolean;
  status: AppStatus;
  list: {
    contacts: Contact[];
    onlineContacts: Contact[];
    roomUsers: RoomUser[] | null;
    roomGroups: RoomGroup[] | null;
    typings: Typing[];
  };
  currentChatTab: CHAT_TYPE;
  currentNavTab: ROUTE_NAMES;
  notiPanelOpen: boolean;
}

const initialState: AppState = {
  socketNeedToSync: true,
  status: {
    isEnableNotificationForAppSetting: false,
    isEnableOnlineStatusForMyAccount: false,
    isWelcomeActive: false,
  },
  list: {
    contacts: [],
    onlineContacts: [],
    roomUsers: null,
    roomGroups: null,
    typings: [],
  },
  currentNavTab: ROUTE_NAMES.CHAT_HISTORY,
  currentChatTab: CHAT_TYPE.GROUP,
  notiPanelOpen: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSocketNeedToSync: (state, action: PayloadAction<boolean>) => {
      state.socketNeedToSync = action.payload;
    },

    setNotificationStatusForAppSetting: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.status.isEnableNotificationForAppSetting = action.payload;
    },

    setContactList: (state, action: PayloadAction<Contact[]>) => {
      state.list.contacts = action.payload;
    },

    setOnlineContactList: (state, action: PayloadAction<Contact[]>) => {
      state.list.onlineContacts = action.payload;
    },

    setRoomUserList: (state, action: PayloadAction<RoomUser[]>) => {
      state.list.roomUsers = action.payload;
    },

    setRoomGroupList: (state, action: PayloadAction<RoomGroup[]>) => {
      state.list.roomGroups = action.payload;
    },

    setTypings: (state, action: PayloadAction<Typing[]>) => {
      state.list.typings = action.payload;
    },

    setCurrentChatTab: (state, action: PayloadAction<CHAT_TYPE>) => {
      state.currentChatTab = action.payload;
    },

    setCurrentNavTab: (state, action: PayloadAction<ROUTE_NAMES>) => {
      state.currentNavTab = action.payload;
    },

    addToOnlineContacts: (state, action: PayloadAction<Contact>) => {
      const validOnline = state.list.onlineContacts.some((contact) => {
        const isAlreadyOnline =
          contact.receiver_id === action.payload.receiver_id;
        const isActive =
          action.payload.active && action.payload.user_busy == '0';

        if (isAlreadyOnline && isActive) return contact;
      });

      if (!validOnline) state.list.onlineContacts.push(action.payload);
    },

    removeFromOnlineContacts: (state, action: PayloadAction<string>) => {
      if (!state.list.onlineContacts) return;

      const contactIndex = state.list.onlineContacts.findIndex(
        (contact) => contact.receiver_id === action.payload
      );

      if (contactIndex >= 0) state.list.onlineContacts.splice(contactIndex, 1);
    },

    updateContactInContacts: (
      state,
      action: PayloadAction<Partial<Contact>>
    ) => {
      if (!state.list.contacts) return;
      const payload = action.payload;

      const contactIndex = state.list.contacts.findIndex(
        (contact) => contact.receiver_id === payload.receiver_id
      );

      if (contactIndex >= 0)
        // updateObject(state.list.contacts[contactIndex], payload);
        state.list.contacts[contactIndex] = {
          ...state.list.contacts[contactIndex],
          ...payload,
        };
    },

    updateUserInRoomUsers: (
      state,
      action: PayloadAction<Partial<RoomUser>>
    ) => {
      if (!state.list.roomUsers) return;

      const payload = action.payload;
      const userIndex = state.list.roomUsers?.findIndex(
        (user) => user.receiver_id === payload.receiver_id
      );

      if (userIndex >= 0)
        // updateObject(state.list.roomUsers[userIndex], payload);
        state.list.roomUsers[userIndex] = {
          ...state.list.roomUsers[userIndex],
          ...payload,
        };
    },

    updateOneToOneLists: (
      state,
      action: PayloadAction<{ receiver_id: string }>
    ) => {
      if (!state.list.roomUsers) return;
      const payload = action.payload;

      const oneToOneIndex = state.list.roomUsers?.findIndex(
        (one) => one.receiver_id === payload.receiver_id
      );

      if (oneToOneIndex >= 0) state.list.roomUsers.splice(oneToOneIndex, 1);
    },

    pushNewGroup: (state, action: PayloadAction<RoomGroup>) => {
      if (!state.list.roomGroups) return;
      const alreadyInGroup = state.list.roomGroups.some(
        (group) => group._id === action.payload._id
      );

      if (alreadyInGroup) return;
      state.list.roomGroups?.unshift(action.payload);
    },

    updateGroupList: (state, action: PayloadAction<{ groupId: string }>) => {
      if (!state.list.roomGroups) return;
      const payload = action.payload;
      const groupIndex = state.list.roomGroups?.findIndex(
        (group) => group._id === payload.groupId
      );

      if (groupIndex >= 0) state.list.roomGroups.splice(groupIndex, 1);
    },

    updateGroupInRoomGroups: (
      state,
      action: PayloadAction<Partial<RoomGroup>>
    ) => {
      if (!state.list.roomGroups) return;
      const payload = action.payload;

      const groupIndex = state.list.roomGroups?.findIndex(
        (group) => group._id === payload._id
      );

      if (groupIndex >= 0)
        // updateObject(state.list.roomGroups[groupIndex], payload);
        state.list.roomGroups[groupIndex] = {
          ...state.list.roomGroups[groupIndex],
          ...payload,
        };
    },

    // updateLastMessageInRoomGroups: (
    //   state,
    //   action: PayloadAction<{
    //     lastMessage: Partial<LastMessage>;
    //     groupId: string;
    //     messageId: string | string[];
    //   }>
    // ) => {
    //   if (!state.list.roomGroups) return;
    //   const payload = action.payload;

    //   const groupIndex = state.list.roomGroups?.findIndex(
    //     (group) => group._id === payload.groupId
    //   );

    //   if (groupIndex >= 0)
    //     state.list.roomGroups[groupIndex].lastMessage = {
    //       ...state.list.roomGroups[groupIndex].lastMessage!,
    //       ...payload.lastMessage,
    //     };

    // },

    updateTypingInRoomGroups: (state, action: PayloadAction<TypingMembers>) => {
      if (!state.list.roomGroups) return;

      const payload = action.payload;

      const groupIndex = state.list.roomGroups?.findIndex(
        (group) => group._id === payload.groupId
      );

      if (groupIndex < 0) return;

      const foundGroup = state.list.roomGroups[groupIndex];

      if (payload.isTyping) {
        state.list.roomGroups[groupIndex].lastTypingMember = {
          senderId: payload.senderId,
          senderName: payload.senderName,
        };
      } else {
        if (foundGroup.lastTypingMember?.senderId === payload.senderId) {
          state.list.roomGroups[groupIndex].lastTypingMember = null;
        }
      }
    },

    updateLastActivityOnetoOneChat: (
      state,
      action: PayloadAction<{ contact_id: string }>
    ) => {
      if (!state.list.roomUsers || state.list.roomUsers?.length === 0) return;
      const chatIndex = state.list.roomUsers?.findIndex(
        (user) => user.contact_id === action.payload.contact_id
      );

      if (chatIndex >= 0) {
        const [selectedChat] = state.list.roomUsers.splice(chatIndex, 1);

        state.list.roomUsers.unshift(selectedChat);
      }
    },

    updateLastActivityGroupChat: (
      state,
      action: PayloadAction<{ groupId: string }>
    ) => {
      if (!state.list.roomGroups || state.list.roomGroups?.length === 0) return;
      const chatIndex = state.list.roomGroups?.findIndex(
        (group) => group._id === action.payload.groupId
      );

      if (chatIndex >= 0) {
        const [selectedChat] = state.list.roomGroups.splice(chatIndex, 1);

        state.list.roomGroups.unshift(selectedChat);
      }
    },

    setNotiPanelOpenState: (
      state,
      { payload }: PayloadAction<boolean | null>
    ) => {
      if (!payload) {
        state.notiPanelOpen = !state.notiPanelOpen;
      } else {
        state.notiPanelOpen = payload;
      }
    },

    // for OneToONe

    // updateGroupAdmin: (
    //   state,
    //   action: PayloadAction<{
    //     groupId: string;
    //     groupUsers: any[];
    //     userId: string;
    //   }>
    // ) => {
    //   if (!state.list.roomGroups) return;
    //   const payload = action.payload;

    //   const groupIndex = state.list.roomGroups?.findIndex(
    //     (group) => group._id === payload.groupId
    //   );
    //   if (groupIndex >= 0) {
    //     const currentUser = payload.groupUsers.find(
    //       (user) => user.user_id[0] === payload.userId
    //     );
    //     state.list.roomGroups[groupIndex].isAdmin = currentUser.is_admin;
    //   }
    // },
  },
});

export const {
  setSocketNeedToSync,
  setTypings,
  setNotiPanelOpenState,

  // ONE TO ONE
  setContactList,
  setOnlineContactList,
  setRoomUserList,
  setRoomGroupList,
  addToOnlineContacts,
  removeFromOnlineContacts,
  updateContactInContacts,
  updateUserInRoomUsers,
  setCurrentChatTab,
  setCurrentNavTab,
  updateOneToOneLists,
  updateLastActivityOnetoOneChat,

  // GROUP
  pushNewGroup,
  updateGroupList,
  updateGroupInRoomGroups,
  updateTypingInRoomGroups,
  updateLastActivityGroupChat,
  // updateGroupAdmin,
} = appSlice.actions;

export default appSlice.reducer;
