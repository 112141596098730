import { BtnOutlined } from 'components/common/Form/Btn';
import Input from 'components/common/Form/Input';
import Logo from 'components/common/Logo/Logo';
import { useForm } from 'react-hook-form';
import InputPassword from 'components/common/Form/InputPassword';
import useApi from 'hooks/useApi';
import { login } from 'queries/auth';
import { useProfile } from 'hooks/useProfile';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PageLoader from 'components/common/PageLoader/PageLoader';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-hot-toast';

function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { invokeApiService } = useApi();
  const { setUserProfile } = useProfile();
  const { setAuthInfoToBrowserStorage } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const res = await invokeApiService(login(data));

      if (res) {
        if (res.data.status === 'fail') {
          toast.error(res.data.message);
          setLoading(false);
        } else {
          toast.success('Successfully logged in!');
          setAuthInfoToBrowserStorage(res.data);
          setUserProfile(res.data.data.user);
          navigate('/');
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRememberMe = () => {
    console.log('Remember me');
  };

  return (
    <>
      {loading && <PageLoader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="layoutPadding bg-[white] max-w-[550px] flex flex-col justify-center"
      >
        <Logo width={'w-auto'} height={'h-12'} />
        <div className="w-full h-10"></div>

        <div className="text-3xl font-bold text-text">Glad to see ya!</div>
        <div className="w-full h-10"></div>

        <Input
          placeholder="Enter Phone Number"
          label="Phone number"
          name="phone"
          errors={errors}
          validationSchema={{ required: 'Phone number is required!' }}
          register={register}
        />
        <div className="w-full h-10"></div>

        <InputPassword
          placeholder="Enter Password"
          label="Password"
          name="password"
          errors={errors}
          validationSchema={{ required: 'Password is required!' }}
          register={register}
        />
        <div className="w-full h-10"></div>

        <div className="flex justify-between items-center gap-5">
          <div className="flex-1 flex items-center gap-1">
            <input
              onChange={(e) => {
                if (e.target.checked) {
                  handleRememberMe();
                }
              }}
              type={'checkbox'}
              id="remember"
            />
            <label
              className="cursor-pointer hover:text-primary transition ease-in-out"
              htmlFor="remember"
            >
              Remember me
            </label>
          </div>

          <div className="flex-1">
            <BtnOutlined type="submit" title="SIGN IN" fullWidth />
          </div>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
